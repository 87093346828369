import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const isShutterQuotationValid = (quotation) => {

  var result = {
    header: 'Success',
    message: 'Quotation valid',
    success: true
  }

  var i = 0;

  for (i = 0; i < quotation.shutter.length; i++) {
    if (quotation.shutter[i].box === null) {
      result.header = 'Error';
      result.message = 'Box for shutter item row ' + (i + 1) + ' is not selected'
      result.success = false;
      return result
    }
    else if (quotation.shutter[i].sector === null) {
      result.header = 'Error';
      result.message = 'Sector for shutter item row ' + (i + 1) + ' is not selected'
      result.success = false;
      return result
    }
    else if (quotation.shutter[i].sector.selectedPricing === null) {
      result.header = 'Error';
      result.message = 'Sector color for shutter item row ' + (i + 1) + ' is not selected'
      result.success = false;
      return result
    }
  }

  return result;
}

export const getQuotationShutterTotal = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += getShutterItemTotal(quotation.shutter[i])
    }
  }
  return total
}

export const getQuotationShutterItemsNames = (quotation, productsData, itemName) => {
  const uniqueNames = new Set(); // To store unique names

  // Check if quotation and shutter section exist
  if (quotation && quotation.shutter && Array.isArray(quotation.shutter)) {
    // Loop through each item in the shutter array
    quotation.shutter.forEach((item) => {
      // Check if the itemName path and id exist
      if (item[itemName] && item[itemName].id) {
        const id = item[itemName].id;
        const productName = productsData[id]?.info?.name;

        // If productName exists
        if (productName) {
          let cleanedName = productName;

          // Remove torque part only if itemName is 'motor'
          if (itemName === 'motor') {
            cleanedName = cleanedName.replace(/(?:\s*\d+\s*Nm|\s*\d+\/\d+\s*Nm)/g, '').trim();
          }

          // Add the cleaned product name to the set
          if (cleanedName) {
            uniqueNames.add(cleanedName);
          }
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};


export const getQuotationShutterItemsFields = (quotation, itemName, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and shutter section exist
  if (quotation && quotation.shutter && Array.isArray(quotation.shutter)) {
    // Loop through each item in the shutter array
    quotation.shutter.forEach((item) => {
      // Check if the itemName path and fieldName exist
      if (item[itemName] && item[itemName][fieldName]) {
        uniqueFields.add(item[itemName][fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};


export const AddShutterItem = (quotation) => {
  return {
    ...quotation,
    shutter: [...(quotation.shutter || []), {
      window: '',
      width: 1,
      height: 1,
      area: 1,
      printMotor: true,
      printable: true,
      box: null,
      sector: null,
      motor: null,
    }],
  };
};

export const copyLastShutterItem = (quotation) => {
  // Check if quotation has any shutter items
  if (!quotation.shutter || quotation.shutter.length === 0) {
    return quotation; // No shutters to copy, return the original quotation
  }

  // Deep clone the last shutter item
  const lastShutterItem = _.cloneDeep(quotation.shutter[quotation.shutter.length - 1]);

  // Modify any properties as needed for the new shutter item
  const newShutterItem = {
    ...lastShutterItem,
    window: '', // Reset window or any other field that shouldn't be copied exactly
  };

  // Return a new quotation with the new shutter item added
  return {
    ...quotation,
    shutter: [...quotation.shutter, newShutterItem],
  };
};


export const RemoveShutterItem = (quotation, index) => {
  if (index > -1 && index !== undefined) {
    const updatedQuotation = { ...quotation };

    // Check if 'shutter' exists in updatedQuotation and it's an array
    if (updatedQuotation.shutter && Array.isArray(updatedQuotation.shutter)) {
      // Create a new array without the item at the specified index
      updatedQuotation.shutter = [...updatedQuotation.shutter.slice(0, index), ...updatedQuotation.shutter.slice(index + 1)];

      // If the 'shutter' array is empty, delete it
      if (updatedQuotation.shutter.length === 0) {
        delete updatedQuotation.shutter;
      }

      return updatedQuotation;
    }
  }

  // If index is invalid or 'shutter' is not an array, return the original quotation
  return quotation;
};

export const getQuotationShutter = (quotation) => (quotation && quotation.shutter) ? quotation.shutter : [];


export const getShutterTotalArea = (quotation) => {
  if (Array.isArray(quotation?.shutter) && quotation?.shutter?.length > 0) {
    return quotation?.shutter.reduce((total, item) => {
      if (item && item.area) {
        return total + item.area;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getShutterTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += quotation.shutter[i].width;
    }
  }
  return total
}

export const getShutterTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += quotation.shutter[i].height;
    }
  }

  return total
}

export const getShutterPriceSqr = (quotation) => {
  if (!quotation || !Array.isArray(quotation.shutter)) {
    return 0;
  }

  return quotation.shutter.reduce((total, shutter) => {
    return total + getShutterItemPriceSqr(shutter);
  }, 0);
};


export const getShutterAreaTotal = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += getShutterItemAreaTotal(quotation.shutter[i])
    }
  }
  return total
}

export const getShutterItemsLength = (quotation) => {
  var length = 0;
  if (quotation.shutter) {
    length = quotation.shutter.length;
  }
  return length;
};

export const getShutterTotalMotors = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      if (quotation.shutter[i].motor) {
        total += quotation.shutter[i].motor.salesPrice
      }
    }
  }
  return total
}



export const getQuotationShutterItemTorque = (quotation, productsData, index) => {
  const sector = quotation.shutter[index]?.sector;

  return sector
    ? (quotation.shutter[index].area * productsData[sector.id].info.weight) / 1.25
    : 0;
};

export const getShutterItemPriceSqr = (shutterItem) => {
  var selectedSalesPrice = 0;

  if (shutterItem.sector !== null) {
    if (shutterItem.sector.salesPrice) {
      selectedSalesPrice = shutterItem?.sector?.salesPrice
    }
  }

  return selectedSalesPrice;
}

export const getShutterItemAreaTotal = (shutterItem) => {
  var totalShutter = 0;
  const area = shutterItem.area < 1 ? 1 : shutterItem.area;
  totalShutter = getShutterItemPriceSqr(shutterItem) * area;
  return totalShutter;
}


export const getShutterItemTotal = (shutterItem) => {
  var total = 0;
  var motorPrice = 0;
  if (shutterItem.motor !== null) {
    motorPrice = shutterItem.motor.salesPrice;
  }

  total = getShutterItemAreaTotal(shutterItem) + motorPrice
  return total
}

export const setShutterItemDimensions = (prevQuotation, index, dimensionType, value) => {
  const updatedQuotation = resetShutterItemCost(prevQuotation, index);
  const shutter = { ...updatedQuotation.shutter[index] };
  shutter[dimensionType] = parseFloat(value);
  shutter.area = shutter.width * shutter.height;
  updatedQuotation.shutter[index] = shutter;
  return updatedQuotation;
};

export const setShutterItemBox = (prevQuotation, index, productId) => {
  const updatedQuotation = resetShutterItemCost(prevQuotation, index);
  updatedQuotation.shutter[index].box = updatedQuotation.shutter[index].box || {};
  updatedQuotation.shutter[index].box.id = productId;
  return updatedQuotation;
};

export const setShutterItemSector = (prevQuotation, index, sectorId, productsData) => {
  const updatedQuotation = { ...prevQuotation };
  updatedQuotation.shutter[index].sector = updatedQuotation.shutter[index].sector || {};
  updatedQuotation.shutter[index].sector.id = sectorId;

  if (productsData[sectorId]) {
    updatedQuotation.shutter[index].sector = {
      ...updatedQuotation.shutter[index].sector,
      ...productsData[sectorId].info.pricing[0],
    };
  } else {
    console.warn(`Sector product data for ID ${sectorId} not found.`);
  }

  return updatedQuotation;
};

export const setShutterItemMotor = (prevQuotation, index, motorId, productsData) => {
  const updatedQuotation = resetShutterItemCost(prevQuotation, index);

  if (motorId === undefined) {
    // Set motor to null if motorId is undefined
    updatedQuotation.shutter[index].motor = null;
  } else {
    // Otherwise, update motor with the given motorId
    updatedQuotation.shutter[index].motor = updatedQuotation.shutter[index].motor || {};
    updatedQuotation.shutter[index].motor.id = motorId;

    if (productsData[motorId]) {
      updatedQuotation.shutter[index].motor.costPrice = productsData[motorId].info.costPrice;
      updatedQuotation.shutter[index].motor.salesPrice = productsData[motorId].info.salesPrice;
    } else {
      console.warn(`Motor product data for ID ${motorId} not found.`);
    }
  }

  return updatedQuotation;
};


/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getShutterItemSectorCostPrice = (shutterItem) => {
  var sectorCostPrice = 0;
  if (shutterItem.sector !== null) {
    if (shutterItem.sector) {
      sectorCostPrice = shutterItem.sector.costPrice
    }
  }
  return sectorCostPrice
}

export const getShutterItemSectorAreaCostPrice = (shutterItem) => {
  var costPrice = getShutterItemSectorCostPrice(shutterItem)
  var areaCostPrice = costPrice * shutterItem.area;
  return areaCostPrice
}

export const getShutterItemSectorCostDescription = (productsData, shutterItem) => {
  const sectorName = productsData[shutterItem.sector.id]?.info?.name || '';
  const motorName = shutterItem.motor
    ? productsData[shutterItem.motor.id]?.info?.name || ''
    : 'Manual';

  return `${sectorName} / ${motorName}`;
};

export const getShutterItemCostTotalWindow = (shutterItem) => {
  var totalWindow = 0;
  if (shutterItem.motor) {
    totalWindow = shutterItem.motor.costPrice + getShutterItemSectorAreaCostPrice(shutterItem)
  }
  else {
    totalWindow = getShutterItemSectorAreaCostPrice(shutterItem)
  }
  return totalWindow
}

export const getShutterItemCostBoxSizePrice = (shutterItem) => {
  var costPrice = 0;
  if (shutterItem.box.costPrice) {
    costPrice = shutterItem.box.costPrice;
  }
  return costPrice
}

export const getShutterItemCostBoxTotalPrice = (shutterItem) => {
  var boxAreaCostPrice = 0;
  if (shutterItem.box.costPrice) {
    boxAreaCostPrice = shutterItem.box.costPrice * shutterItem.width;
  }
  return boxAreaCostPrice
}

export const getShutterItemTrackTotalPrice = (shutterItem) => {
  var totalPrice = 0;

  if (shutterItem.track) {
    totalPrice = shutterItem.track.costPrice * shutterItem.height * 2
  }
  return totalPrice
}

export const resetShutterItemCost = (quotation, index) => {
  // Create a shallow copy of the quotation object
  const updatedQuotation = { ...quotation };

  if (updatedQuotation.shutter[index]) {
    if (updatedQuotation.shutter[index].box) {
      delete updatedQuotation.shutter[index].box.costPrice;
      delete updatedQuotation.shutter[index].box.size;
    }
    delete updatedQuotation.shutter[index].track;
    delete updatedQuotation.shutter[index].boxSide;
  }

  return updatedQuotation;
}

export const getCostShutterBoxTotalOfTotalPrice = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += getShutterItemCostBoxTotalPrice(quotation.shutter[i])
    }
  }
  return total
}

export const getCostShutterBoxSidesTotalOfTotalPrice = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      if (quotation.shutter[i].boxSide) {
        total += quotation.shutter[i].boxSide.costPrice
      }
    }
  }
  return total
}

export const getCostShutterTracksTotalOfTotalPrice = (quotation) => {
  var total = 0;

  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      if (quotation.shutter[i].track) {
        total += getShutterItemTrackTotalPrice(quotation.shutter[i])
      }
    }
  }


  return total
}

export const getCostShutterTotal = (quotation) => {
  var total = 0;
  total += getCostShutterTotalPrice(quotation)
  total += getCostShutterTotalMotorPrice(quotation)
  total += getCostShutterBoxTotalOfTotalPrice(quotation)
  total += getCostShutterBoxSidesTotalOfTotalPrice(quotation)
  total += getCostShutterTracksTotalOfTotalPrice(quotation)
  return total
}

export const getCostShutterTotalOfTotalWindow = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += getShutterItemCostTotalWindow(quotation.shutter[i])
    }
  }


  return total
}

export const getCostShutterTotalMotorPrice = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      if (quotation.shutter[i].motor) {
        total += quotation.shutter[i].motor.costPrice
      }
    }
  }
  return total
}

export const getCostShutterTotalPrice = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += getShutterItemSectorAreaCostPrice(quotation.shutter[i])
    }
  }

  return total
}

export const getCostShutterTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += quotation.shutter[i].width;
    }
  }

  return total
}

export const getCostShutterTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.shutter) {
    for (var i = 0; i < quotation.shutter.length; i++) {
      total += quotation.shutter[i].height;
    }
  }
  return total
}


export const getCostShutterTotalArea = (quotation) => {
  var total = 0;
  total = getCostShutterTotalWidth(quotation) * getCostShutterTotalHeight(quotation);

  return total
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/

export const isShutterJobOrderCreated = (quotation) => {
  const shutters = getQuotationShutter(quotation);
  return Array.isArray(shutters) && shutters.length > 0 && shutters.every(
    (shutter) =>
      shutter.hasOwnProperty('installationMethod') &&
      shutter.hasOwnProperty('electricityDirection')
  );
};

export const getJobOrderShutterSectorDescription = (productsData, shutterItem) => {
  let description = '';

  if (shutterItem.sector) {
    const { id, colorName } = shutterItem.sector;
    description = `${productsData[id]?.info.name || ''} ${colorName || ''}`;
  }

  if (shutterItem.motor) {
    const { id } = shutterItem.motor;
    description = description.concat(' ', productsData[id]?.info.name || '');
  }

  return description;
};

export const getJobOrderShutterSectorDescriptionPDF = (productsData, shutterItem) => {
  let description = '';

  if (shutterItem.sector) {
    const { id, colorName } = shutterItem.sector;
    description = `${productsData[id]?.info.name || ''} ${colorName || ''}`;
  }

  if (shutterItem.motor && shutterItem.printMotor) {
    const { id } = shutterItem.motor;
    description = description.concat(' ', productsData[id]?.info.name || '');
  }

  return description;
};

export const getJobOrderShutterBoxDescription = (productsData, shutterItem) => {
  if (shutterItem.box) {
    const { id, size } = shutterItem.box;
    const productName = productsData[id]?.info.name || '';
    return `${productName} ${size || ''}`;
  }
  return '';
};
export const setJobOrderShutterNote = (quotation, index, value) => ({
  ...quotation,
  shutter: getQuotationShutter(quotation).map((shutter, i) =>
    i === index ? { ...shutter, note: value } : shutter
  ),
});

export const getJobOrderShutterNote = (shutterItem) => {
  var note = ''
  if (shutterItem.note) {
    note = note.concat(shutterItem.note)
  }
  return note
}

export const getJobOrderShutterPrintMotor = (shutterItem) => !!shutterItem.printMotor;



export const setJobOrderShutterElectricityDirection = (quotation, index, direction) => ({
  ...quotation,
  shutter: getQuotationShutter(quotation).map((shutter, i) =>
    i === index ? { ...shutter, electricityDirection: direction } : shutter
  ),
});


export const getJobOrderShutterElectricityDirection = (quotation, index) => {
  return quotation.shutter[index]?.electricityDirection;
};

export const setJobOrderShutterInstallationMethod = (quotation, index, method) => ({
  ...quotation,
  shutter: getQuotationShutter(quotation).map((shutter, i) =>
    i === index ? { ...shutter, installationMethod: method } : shutter
  ),
});

export const getJobOrderShutterInstallationMethod = (quotation, index) => {
  return quotation.shutter[index]?.installationMethod;
};

export const setJobOrderShutterTracksType = (quotation, index, type) => ({
  ...quotation,
  shutter: getQuotationShutter(quotation).map((shutter, i) =>
    i === index ? { ...shutter, tracksType: type } : shutter
  ),
});

export const getJobOrderShutterTracksType = (quotation, index) => {
  return quotation.shutter[index]?.tracksType;
};
