
import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastBoxSideItem = (quotation) => {
  if (!quotation.boxSides?.length) return quotation;
  const newBoxSideItem = _.cloneDeep(quotation.boxSides[quotation.boxSides.length - 1]);
  return {
    ...quotation,
    boxSides: [...quotation.boxSides, newBoxSideItem],
  };
};

export const getQuotationBoxSideItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and box section exist
  if (quotation && quotation.boxSides && Array.isArray(quotation.boxSides)) {
    // Loop through each item in the box array
    quotation.boxSides.forEach((item) => {
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationBoxSideItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.boxSides && Array.isArray(quotation.boxSides)) {
    // Loop through each item in the blinds array
    quotation.boxSides.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getBoxSideItemsLength = (quotation) => {
  var length = 0;
  if (quotation.boxSides) {
    length = quotation.boxSides.length;
  }
  return length;
};

export const AddBoxSideItem = (quotation) => {
  return {
    ...quotation,
    boxSides: [
      ...(quotation.boxSides || []),
      {
        id: null,
        quantity: 1,
        printable: true,
      },
    ],
  };
};


export const setBoxSideItem = (quotation, index, productId, productsData) => {
  const productPricing = productsData[productId]?.info?.pricing[0] || {};
  const updatedBoxSide = {
    ...quotation.boxSides[index],
    id: productId,
    colorName: productPricing.colorName,
    colorValue: productPricing.colorValue,
    costPrice: productPricing.costPrice,
    salesPrice: productPricing.salesPrice,
    size: productPricing.size
  };
  return {
    ...quotation,
    boxSides: [
      ...quotation.boxSides.slice(0, index),
      updatedBoxSide,
      ...quotation.boxSides.slice(index + 1)
    ]
  };
};

export function setBoxSideQuantity(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.boxSides[index].quantity = parseFloat(value);
  return updatedQuotation;
}

export function setBoxSidePricing(quotation, index, pricingData) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.boxSides[index] = { ...updatedQuotation.boxSides[index], ...pricingData };
  return updatedQuotation;
}

export const RemoveBoxSideItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1 && updatedQuotation.boxSides) {
    updatedQuotation.boxSides.splice(index, 1);

    if (updatedQuotation.boxSides.length === 0) {
      delete updatedQuotation.boxSides;
    }
  }

  return updatedQuotation;
};

export const getQuotationBoxSides = (quotation) => (quotation && quotation.boxSides) ? quotation.boxSides : [];

export const getQuotationBoxSidesTotal = (quotation) => {
  var total = 0;
  if (quotation.boxSides) {
    for (var i = 0; i < quotation.boxSides.length; i++) {
      total += getBoxSideItemTotal(quotation.boxSides[i])
    }
  }

  return total;
}

export const getBoxSidesSubTotal = (quotation) => {
  if (Array.isArray(quotation.boxSides) && quotation.boxSides.length > 0) {
    return quotation.boxSides.reduce((total, boxSide) => {
      if (boxSide && boxSide.salesPrice) {
        return total + boxSide.salesPrice;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getBoxSidesTotalQuantity = (quotation) => {
  if (Array.isArray(quotation.boxSides) && quotation.boxSides.length > 0) {
    return quotation.boxSides.reduce((total, boxSide) => {
      if (boxSide && boxSide.quantity) {
        return total + boxSide.quantity;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getBoxSideItemTotal = (boxSideItem) => {
  var total = 0;
  var salesPrice = boxSideItem.id ? boxSideItem.salesPrice : 0;
  total = (salesPrice ?? 0) * (boxSideItem.quantity ?? 0);
  return total;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getBoxSideItemCostDescription = (productsData, boxSideItem) => {
  return productsData[boxSideItem.id].info.name
}

export const getCostBoxSideItemTotal = (boxSideItem) => {
  const costPrice = boxSideItem?.costPrice ?? 0;
  const quantity = boxSideItem?.quantity ?? 0;
  return costPrice * quantity;
};

export const getCostBoxSidesTotal = (quotation) => {
  var total = 0;
  if (quotation.boxSides) {
    for (var i = 0; i < quotation.boxSides.length; i++) {
      total += getCostBoxSideItemTotal(quotation.boxSides[i])
    }
  }
  return total
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/