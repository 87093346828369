import {
IconAdjustments,
} from '@tabler/icons-react';

const administrative = {
  permissions: ['Admin'],
  id: 'administrative',
  title: 'Administrative',
  type: 'group',
  children: [
    {
      id: 'system',
      title: 'System',
      permissions: ['Admin'],
      type: 'item',
      icon: IconAdjustments,
      url: '/system-management',
    }
  ]
};

export default administrative;
