
export const styles = {
  popper: {
    zIndex: 9999
  },
  termsAndConditions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  logout: {
    m: 2
  }
};
