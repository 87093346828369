import { Box, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import logo from 'assets/images/logo-simple.png';

const NetworkGuard = ({ children }) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const [hasInternetAccess, setHasInternetAccess] = useState(true);

	useEffect(() => {
		// Handle browser's online/offline events
		const handleOffline = () => {
			setIsOnline(false);
			console.log('Network is offline');
		};

		const handleOnline = () => {
			setIsOnline(true);
			console.log('Network is back online');
		};

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		// Interval to check internet access by pinging Google
		const checkInternetAccess = async () => {
			try {
				await fetch('https://www.google.com', { mode: 'no-cors' });
				setHasInternetAccess(true);
			} catch (error) {
				setHasInternetAccess(false);
			}
		};

		const intervalId = setInterval(() => {
			if (navigator.onLine) {
				checkInternetAccess();
			}
		}, 10000); // Check every 10 seconds

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
			clearInterval(intervalId); // Cleanup interval on unmount
		};
	}, []);

	if (!isOnline || !hasInternetAccess) {
		return (
			<Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Box textAlign="center">
					<img style={{ height: 80 }} alt="logo" src={logo} />
					<Typography sx={{ my: 2 }} variant="h4"><strong>Network Connection Lost</strong></Typography>
					<Typography variant="body1" color="textSecondary" mt={2}>
						It seems you are currently offline or have no internet access. Please check your connection and try again.
						We will restore the app's functionality once the connection is back.
					</Typography>
				</Box>
			</Container>
		);
	}

	return <>{children}</>;
};

export default NetworkGuard;