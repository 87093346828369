import {
  firebaseAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  httpsCallable,
  functions
} from 'database/config/firebaseInit';

const createUser = httpsCallable(functions, "createUser");
const changeUserPassword = httpsCallable(functions, "changeUserPassword");

export const authStateChangedHandler = (callback) => {
  return onAuthStateChanged(firebaseAuth, (user) => {
    callback(user);
  });
};

export const signUp = (email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        console.log('User Created successfully!');
        resolve(userCredential.user.uid);
      })
      .catch((error) => {
        console.log('Error Creating user:', error);
        reject(error);
      });
  });
};

export const signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        console.log('User Login success');
        resolve(userCredential.user);
      })
      .catch((error) => {
        console.log('User Login Failed!', error);
        reject(error);
      });
  });
};

export const userSignOut = () => {
  signOut(firebaseAuth);
};

export const adminCreateUser = (email, password) => {
  return new Promise((resolve, reject) => {
    createUser({ email, password })
      .then((result) => {
        const { uid } = result.data;
        console.log('User Created successfully!');
        resolve(uid);
      })
      .catch((error) => {
        let reportedError = '';

        switch (error) {
          case 'auth/invalid-email': {
            reportedError = 'Incorrect email Address'
            break;
          }

          case 'auth/user-not-found':
            {
              reportedError = "Email address not found"
              break;
            }

          case 'auth/wrong-password':
            {
              reportedError = "Wrong password"
              break;
            }
          case 'auth/weak-password':
            {
              reportedError = "Password should be at least 6 characters"
              break;
            }
          case 'auth/email-already-in-use':
            {
              reportedError = "This email address is already used by another user"
              break;
            }

          default: {
            reportedError = "Error, please try again"
          }
        }
        console.error("Failed to create user:", reportedError);
        reject(reportedError);
      });
  });
};


export const adminChangeUserPassword = (userID, newPassword) => {
  return new Promise((resolve, reject) => {
    changeUserPassword({ userID, newPassword })
      .then((result) => {
        console.log('Password changed successfully!');
        resolve(result.data);
      })
      .catch((error) => {
        let reportedError = '';

        switch (error) {
          case 'auth/user-not-found':
            reportedError = "User not found";
            break;

          case 'auth/wrong-password':
            reportedError = "Wrong password";
            break;

          case 'auth/weak-password':
            reportedError = "Password should be at least 6 characters";
            break;

          default:
            reportedError = "Error changing password, please try again";
        }

        console.error("Failed to change user password:", reportedError);
        reject(reportedError);
      });
  });
};


export const resetUserPassword = (email) => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        console.log('Email Sent Successfully!');
        resolve();
      })
      .catch((error) => {
        console.log('Error Failed to send email', error);
        reject(error);
      });
  });
};