import React from 'react';
import { useTheme } from '@emotion/react';
import { generalConfig } from 'panel/cfg/general/config';

const Loading = () => {
  const theme = useTheme();

  const generateSpinnerStyle = (size, color, animationDuration, isBackwards = false) => {
    return {
      position: 'relative',
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: '50%',
      border: '1px solid transparent',
      borderTop: `5px solid ${color}`,
      animation: `${isBackwards ? 'spin-back' : 'spin'} ${animationDuration}s ease-in-out infinite`,
    };
  };

  const loadingStyle = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      height: '150px',
      animation: 'none !important',
    },
    keyframes: `
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes spin-back {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    `,
  };

  return (
    <div style={loadingStyle.container}>
      <div style={generateSpinnerStyle(200, theme.palette.orange.main, 1)} />
      <div style={loadingStyle.overlay}>
        <img style={loadingStyle.text} src={generalConfig.logo.main} alt="Logo" />
      </div>
      <div style={loadingStyle.overlay}>
        <div style={generateSpinnerStyle(220, theme.palette.primary.main, 2)} />
      </div>
      <div style={loadingStyle.overlay}>
        <div style={generateSpinnerStyle(180, theme.palette.secondary.main, 2, true)} />
      </div>
      <style>{loadingStyle.keyframes}</style>
    </div>
  );
};

export default Loading;
