import { getServerTimestampNow } from 'database/repositories/SystemRepo';
import {
  firestoreDatabase,
  collection,
  where,
  orderBy,
  query,
  getDoc,
  doc,
  increment,
  startAfter,
  getDocs,
  setDoc,
  onSnapshot,
  updateDoc,
  limit,
  deleteDoc,
  getCountFromServer,
  writeBatch,
  Timestamp,
  functions,
  httpsCallable,
} from 'database/config/firebaseInit';

export const getFirestoreServerTimestampNow = async () => {
  const localStorageKey = "serverTimeOffset";
  const validityKey = "serverTimeOffsetTimestamp";
  const validityPeriod = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds

  const savedOffset = localStorage.getItem(localStorageKey);
  const savedTimestamp = localStorage.getItem(validityKey);

  // Check if the saved timestamp is valid
  if (savedOffset && savedTimestamp) {
    const currentTime = Date.now();
    const offsetTime = Number(savedTimestamp);

    // If the offset is valid, calculate server time using the local time and the offset
    if (currentTime - offsetTime < validityPeriod) {
      const localTime = new Date();
      const serverTime = new Date(localTime.getTime() + Number(savedOffset));
      // console.log('Server Time', Timestamp.fromDate(serverTime).toDate())
      // console.log('Machine Time', new Date())
      return Timestamp.fromDate(serverTime);
    }
  }

  // If no valid offset, call the server to get the accurate server time
  try {
    const func = httpsCallable(functions, "getServerTime");
    const result = await func();

    if (result.data && result.data.timestamp) {
      const serverTimestamp = Number(result.data.timestamp);
      const serverDate = new Date(serverTimestamp);

      // Calculate the offset between server time and local time
      const localTime = new Date();
      const offset = serverDate.getTime() - localTime.getTime();

      // Save the offset and current timestamp in localStorage for future use
      localStorage.setItem(localStorageKey, offset);
      localStorage.setItem(validityKey, Date.now().toString()); // Save current timestamp

      return Timestamp.fromDate(serverDate);
    }
  } catch (error) {
    console.error("Error retrieving server timestamp, falling back to local date.", error);
  }

  // Fallback to the local date if server call fails
  return Timestamp.fromDate(new Date());
};

export const getFirebaseTimestampNow = async () => {
  const timestamp = await getFirestoreServerTimestampNow();
  return timestamp;
};

export const getFirebaseDateNow = async () => {
  return (await getFirebaseTimestampNow()).toDate();
};

export const dateToFirebaseTimestamp = (date) => {
  return Timestamp.fromDate(date);
};

export const firebaseTimestampToDate = (timestamp) => {
  return timestamp.toDate();
};

export const firestoreGetPushKey = async (path) => {
  return new Promise((resolve, reject) => {
    try {
      const docRef = doc(collection(firestoreDatabase, path));
      resolve(docRef.id);
    }
    catch (error) {
      console.error(error)
      reject(error);
    }
  });
};

export const incrementFirestoreField = async (path, fieldName, incrementBy) => {
  const documentRef = doc(firestoreDatabase, path);

  return new Promise((resolve, reject) => {
    updateDoc(documentRef, {
      [fieldName]: increment(incrementBy),
    })
      .then(() => {
        resolve();
        console.log('incrementFirestoreField Success');
      })
      .catch((error) => {
        reject(error);
        console.log('incrementFirestoreField Fail');
      });
  });
};

export const registerDocumentListener = async (path, callback) => {
  const documentRef = doc(firestoreDatabase, path);

  const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      callback(docSnapshot.data());
    } else {
      callback(null);
    }
  }, (error) => {
    console.log(error);
    callback(null);
  });

  return unsubscribe;
};

export const registerCollectionListener = async (path, callback, filters = [], orders = [], limitValue = 0) => {

  let baseQuery = query(collection(firestoreDatabase, path));

  for (const filter of filters) {
    baseQuery = query(baseQuery, where(filter.key, filter.op, filter.value));
  }

  for (const order of orders) {
    baseQuery = query(baseQuery, orderBy(order.key, order.value));
  }

  if (limitValue !== 0) {
    baseQuery = query(baseQuery, limit(limitValue));
  }


  const unsubscribe = onSnapshot(baseQuery, (querySnapshot) => {
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
  }, (error) => {
    console.log(error);
    callback(null);
  });

  return unsubscribe;
};

export const firestoreUpdateObject = async (path, object) => {
  const documentRef = doc(firestoreDatabase, path);

  return updateDoc(documentRef, object)
    .then(() => {
      console.log('firestoreUpdateObject Success');
      return object;
    })
    .catch((error) => {
      console.error('firestoreUpdateObject Fails:', error);
      throw error;
    });
};

export const firestoreSetObject = async (path, object) => {
  const documentRef = doc(firestoreDatabase, path);

  const timeNow = await getServerTimestampNow();
  object.updated = timeNow
  if (!object.created) {
    object.created = timeNow;
  }

  return setDoc(documentRef, object)
    .then(() => {
      console.log('firestoreSetObject Success');
      return object;
    })
    .catch((error) => {
      console.error('firestoreSetObject Fails:', error);
      throw error;
    });
};

export const firestoreDeleteObject = async (path) => {
  const documentRef = doc(firestoreDatabase, path);
  return deleteDoc(documentRef)
    .then(() => {
      console.log('firestoreDeleteObject Success');
    })
    .catch((error) => {
      console.error('firestoreDeleteObject Fail:', error);
      throw error;
    });
};

export const checkDocumentExists = async (path) => {
  const documentRef = doc(firestoreDatabase, path);

  return getDoc(documentRef)
    .then((doc) => {
      console.log('checkDocumentExists Success');
      return doc.exists();
    })
    .catch((error) => {
      console.log('checkDocumentExists Fail');
      throw error;
    });
};

export const getFirestoreObjectField = async (path, fields) => {
  const documentRef = doc(firestoreDatabase, path);

  return getDoc(documentRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const selectedData = fields.reduce((result, field) => {
          if (data.hasOwnProperty(field)) {
            result[field] = data[field];
          }
          return result;
        }, {});
        console.log('getFirestoreObjectField Success');
        return selectedData;
      } else {
        console.log('getFirestoreObjectField Fail');
        return null;
      }
    })
    .catch((error) => {
      console.log('getFirestoreObjectField Fail', error);
      throw error;
    });
};

export const firestoreGetObjectByID = async (path) => {
  const documentRef = doc(firestoreDatabase, path);

  return getDoc(documentRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        console.log('firestoreGetObjectByID Success');
        return docSnapshot.data();
      } else {
        console.log('firestoreGetObjectByID Fail');
        return null;
      }
    })
    .catch((error) => {
      console.log('firestoreGetObjectByID Fail', error);
      throw error;
    });
};

export const getMultipleDocumentsByID = async (path, ids) => {
  const documentRefs = ids.map((id) => doc(firestoreDatabase, path, id));

  try {
    const snapshots = await Promise.all(documentRefs.map((documentRef) => getDoc(documentRef)));
    const list = snapshots.map((snapshot) => snapshot.data());
    console.log('getMultipleDocumentsByID Success');
    return list;
  } catch (error) {
    console.error('getMultipleDocumentsByID Fails:', error);
    throw error;
  }
};


export const firestoreGetCollection = async (path, filters, orders, limitValue) => {
  return new Promise((resolve, reject) => {

    let baseQuery = query(collection(firestoreDatabase, path));

    for (const filter of filters) {
      baseQuery = query(baseQuery, where(filter.key, filter.op, filter.value));
    }

    for (const order of orders) {
      baseQuery = query(baseQuery, orderBy(order.key, order.value));
    }

    if (limitValue !== 0) {
      baseQuery = query(baseQuery, limit(limitValue));
    }

    getDocs(baseQuery)
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (!querySnapshot.empty) {
          console.log('firestoreGetCollection Success');
          resolve(data);
        }
        else {
          console.log('firestoreGetCollection Empty');
          resolve(null);
        }
      })
      .catch((error) => {
        console.error('firestoreGetCollection Fail', error);
        reject(error);
      });
  });
};

export const firestorePushObject = async (path, object) => {
  try {
    const id = await firestoreGetPushKey(path);
    object.id = id;
    object.created = await getServerTimestampNow();

    await setDoc(doc(firestoreDatabase, `${path}/${object.id}`), object);
    console.log('firestorePushObject Success');
    return object.id;
  }
  catch (error) {
    console.error('firestorePushObject Fails:', error);
    throw error;
  }
};

export const firestorePushObjectNoCreated = async (path, object) => {
  try {
    const id = await firestoreGetPushKey(path);
    object.id = id;
    await setDoc(doc(firestoreDatabase, `${path}/${object.id}`), object);
    console.log('firestorePushObject Success');
    return object.id;
  }
  catch (error) {
    console.error('firestorePushObject Fails:', error);
    throw error;
  }
};

export const firestoreGenaricPagination = async (path, filters, orders, limitValue, last) => {
  try {
    let queryRef = collection(firestoreDatabase, path);

    // Add filters
    filters.forEach((filter) => {
      queryRef = query(queryRef, where(filter.key, filter.op, filter.value));
    });

    // Add orders
    orders.forEach((order) => {
      queryRef = query(queryRef, orderBy(order.key, order.value));
    });

    // Add last item reference for pagination
    if (last !== null) {
      queryRef = query(queryRef, startAfter(last));
    }

    // Add query limit
    queryRef = query(queryRef, limit(limitValue));

    const querySnapshot = await getDocs(queryRef);
    const lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (!querySnapshot.empty) {
      const records = querySnapshot.docs.map((doc) => doc.data());
      const result = {
        records: records,
        lastItem: lastItem,
      };
      console.log('firestoreGenaricPagination Success');
      return result;
    }
    else {
      const result = {
        records: [],
        lastItem: lastItem,
      };
      console.log('firestoreGenaricPagination Empty');
      return result;
    }
  } catch (error) {
    console.error('firestoreGenaricPagination Fail', error);
    throw error;
  }
};

export const firestoreBatchUpdate = async (path, objects, progressCallback) => {
  try {
    const batchArray = [];
    let operationCounter = 0;
    let batchIndex = 0;
    let totalBatches = Math.ceil(objects.length / 500);
    let completedBatches = 0;

    objects.forEach((object) => {
      const ref = doc(firestoreDatabase, path, object.id);
      if (!batchArray[batchIndex]) {
        batchArray[batchIndex] = writeBatch(firestoreDatabase);
      }
      batchArray[batchIndex].update(ref, object);
      operationCounter++;

      if (operationCounter === 499) {
        batchIndex++;
        operationCounter = 0;
      }
    });

    for (const batch of batchArray) {
      await batch.commit();
      completedBatches++;
      let progress = Math.floor((completedBatches / totalBatches) * 100);
      progressCallback(progress);
    }

    console.log('firestoreBatchUpdate Success');
    return;
  } catch (error) {
    console.error('firestoreBatchUpdate Fails:', error);
    throw error;
  }
};

export const firestoreBatchPush = async (path, objects, progressCallback) => {
  try {
    const batchArray = [];
    let operationCounter = 0;
    let batchIndex = 0;
    let totalBatches = Math.ceil(objects.length / 500);
    let completedBatches = 0;

    objects.forEach((object) => {
      const docRef = doc(collection(firestoreDatabase, path));
      object.id = docRef.id;
      const ref = doc(firestoreDatabase, path, object.id);
      if (!batchArray[batchIndex]) {
        batchArray[batchIndex] = writeBatch(firestoreDatabase);
      }
      batchArray[batchIndex].set(ref, object);
      operationCounter++;

      if (operationCounter === 499) {
        batchIndex++;
        operationCounter = 0;
      }
    });

    for (const batch of batchArray) {
      await batch.commit();
      completedBatches++;
      let progress = Math.floor((completedBatches / totalBatches) * 100);
      progressCallback(progress);
    }

    console.log('firestoreBatchPush Success');
    return;
  } catch (error) {
    console.error('firestoreBatchPush Fails:', error);
    throw error;
  }
};

export const firestoreGetCollectionCount = async (path, filters, orders) => {
  return new Promise((resolve, reject) => {
    let baseQuery = query(collection(firestoreDatabase, path));

    for (const filter of filters) {
      baseQuery = query(baseQuery, where(filter.key, filter.op, filter.value));
    }

    for (const order of orders) {
      baseQuery = query(baseQuery, orderBy(order.key, order.value));
    }

    getCountFromServer(baseQuery)
      .then((querySnapshot) => {
        console.log('firestoreGetCollectionCount Success');
        resolve(querySnapshot.data().count);
      })
      .catch(function (error) {
        console.log('firestoreGetCollectionCount Fail', error);
        reject(error);
      });
  });
};

export const firestoreExecFunction = (functionName, data) => {
  return new Promise((resolve, reject) => {
    httpsCallable(functions, functionName)(data)
      .then((result) => {
        const { success } = result.data;
        console.log(`Function ${functionName} executed`);
        resolve(success);
      })
      .catch((error) => {
        console.error(`Faild to execute Function ${functionName}`);
        reject(error);
      });
  });
};
