// NotificationCard.js
import React, { forwardRef } from 'react';
import { Card, CardContent, Stack, Typography, Chip, Box } from '@mui/material';
import logo from 'assets/images/logo-simple.png';
import { getHumanReadableFormat } from 'utility/time';
import { useTheme } from '@mui/material/styles'; // Ensure to import useTheme
import { useNavigate } from 'react-router-dom';

const NotificationCard = forwardRef(({ notification, onClick, mode, sx }, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isUrl = notification?.template !== 'System';

  const handleNotificationClickedInternal = () => {
    if (notification?.template === 'Order') {
      navigate(`/orders/${notification.url}`);
    }

  }

  return (
    <Card
      onClick={() => {
        onClick(notification)
        handleNotificationClickedInternal()
      }}
      sx={{
        cursor: isUrl ? 'pointer' : 'initial',
        minWidth: 300,
        border: 0,
        boxShadow: mode === 'snack' ? 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' : 'none',
        background: notification?.seen ? `rgba(${theme.palette.secondary.main}, 0.1)` : theme.palette.secondary.main,
        '&:hover': {
          background: isUrl ? theme.palette.secondary.light : 'initial',
          transition: "0.1s",
        },
        ...sx
      }}
      variant="outlined"
    >
      <CardContent>
        <Stack>

          <Stack sx={{ mb: 1 }} direction={'row'} alignItems={'center'} spacing={2}>
            <img
              alt="logo"
              src={logo}
              style={{
                maxWidth: '30px',
              }}
            />
            <Stack sx={{ flexGrow: 1 }}>
              <Box display="flex" alignItems="center">
                <Typography sx={{ mr: 1 }}><strong>{notification.title}</strong></Typography>
                {!notification.seen && (
                  <Chip
                    label="New"
                    size="small"
                    color="success" // You can customize the color
                    sx={{ ml: 1, marginLeft: 'auto' }} // Add left margin for spacing
                  />
                )}
              </Box>
              <Typography variant="caption">{getHumanReadableFormat(notification.created)}</Typography>
            </Stack>
          </Stack>
          <Typography sx={{ pl: 2 }} variant="body2">{notification.body}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
});


export default NotificationCard;