import {
  IconNotification,
} from '@tabler/icons-react';

const notifications = {
  permissions: [
    'Admin',
  ],
  id: 'notifications',
  title: 'Notifications',
  type: 'group',
  children: [
    {
      id: 'notifications',
      title: 'Notifications',
      permissions: [
        'Admin',
      ],
      type: 'item',
      icon: IconNotification,
      url: '/notifications',
    }
  ]
};

export default notifications;
