import { lazy } from 'react';

// project imports
import Loadable from 'Loadable';
import MinimalLayout from 'panel/src/views/layout/MinimalLayout';
const NotFound = Loadable(lazy(() => import('panel/src/pages/NotFound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const notFoundRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default notFoundRoutes;
