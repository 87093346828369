
export const parseValue = (value) =>
{
  const parsedValue = parseFloat(value);
  return Number.isInteger(parsedValue) ? parsedValue : parseFloat(parsedValue.toFixed(2));
}

export const viewCurrency = (value, hideSymbol = false) =>
{
  const formattedValue = new Intl.NumberFormat('en-EG', {
    style: 'currency',
    currency: 'EGP',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  const symbol = 'EGP';
  return formattedValue.replace(symbol, '').trim() + ' ' + (hideSymbol ? '' : symbol);
};

export const viewArabicCurrency = (value) =>
{
  const formattedValue = new Intl.NumberFormat('ar-EG', {
    style: 'currency',
    currency: 'EGP',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  const symbol = 'ج.م.';
  return formattedValue.replace(symbol, '').trim() + ' ' + symbol;
};

export const viewPercentage = (value) =>
{
  const fractionDigits = Number.isInteger(value) ? 0 : 1;

  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value / 100);
};

export const viewNumber = (value, decimalPlaces = 2) =>
{
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces + 1,
  }).format(value);
};