
import {
  firestorePushObject,
  firestoreSetObject,
} from 'database/firebase/api/database'


export const addNewNotificaion = (notificaion) => {
  return new Promise(async (resolve, reject) => {
    await firestorePushObject(`notifications`, notificaion)
      .then((data) => {
        console.log('addNewNotificaion success')
        resolve(data);
      })
      .catch((error) => {
        console.log('addNewNotificaion Fail')
        reject(error);
      });
  });
}

export const updateNotificaion = (userID, notificaionID, object) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Update the notificaion in Firestore
      const notificaion = await firestoreSetObject(`users/${userID}/notifications/${notificaionID}`, object);

      console.log('updateNotificaion success');
      resolve(notificaion);
    } catch (error) {
      console.log('updateNotificaion Fail');
      reject(error);
    }
  });
}