import PropTypes from 'prop-types';
import { cloneElement, forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'panel/src/store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { databaseConfig } from 'panel/cfg/database/confg';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const selected = customization.isOpen.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    // Split the current path into segments
    const pathSegments = document.location.pathname.toString().split('/');

    // Get the first meaningful segment or default to "dashboard"
    const currentPathSegment = pathSegments[1] || 'dashboard';

    // Check if the first segment matches the item ID
    if (currentPathSegment === item.id) {
      dispatch({ type: MENU_OPEN, id: item.id });
    } else if (currentPathSegment === 'dashboard') {
      dispatch({ type: MENU_OPEN, id: 'dashboard' }); // Ensure to handle the default case
    }
    // eslint-disable-next-line
  }, [pathname]);

  if (item?.hidden) {
    return null
  }

  return (
    databaseConfig.isAuthorized(item.permissions,
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={{
          py: 0.8,
          borderRadius: '15px'
        }}
        selected={selected}
        onClick={() => itemHandler(item.id)}
      >
        <ListItemIcon>
          {cloneElement(itemIcon, {
            size: 20,
            color: selected ? theme.palette.primary.main : undefined,
            stroke: selected ? 1.8 : 1.2
          })}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant='body2'
              color={selected ? theme.palette.primary.dark : 'default'}
              sx={{
                letterSpacing: 1,
                fontWeight: selected ? 700 : 'initial'
              }}>
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    )
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
