import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const getQuotationSectorItemsFields = (quotation, itemName, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and sector section exist
  if (quotation && quotation.sectors && Array.isArray(quotation.sectors)) {
    // Loop through each item in the sector array
    quotation.sectors.forEach((item) => {
      // Check if the itemName path and fieldName exist
      if (item[itemName] && item[itemName][fieldName]) {
        uniqueFields.add(item[itemName][fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getSectorItemsLength = (quotation) => {
  var length = 0;
  if (quotation.sectors) {
    length = quotation.sectors.length;
  }
  return length;
};
export const getQuotationSectorsItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.sectors && Array.isArray(quotation.sectors)) {
    // Loop through each item in the blinds array
    quotation.sectors.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const copyLastSectorItem = (quotation) => {
  if (!quotation.sectors?.length) return quotation;
  const newSectorItem = _.cloneDeep(quotation.sectors[quotation.sectors.length - 1]);
  return {
    ...quotation,
    sectors: [...quotation.sectors, newSectorItem],
  };
};

export const getQuotationSectorsTotal = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += getSectorItemAreaTotal(quotation.sectors[i])
    }
  }

  return total;
}

export const AddSectorItem = (quotation) => {
  return {
    ...quotation,
    sectors: [...(quotation.sectors || []), {
      id: null,
      width: 1,
      height: 1,
      area: 1,
      printable: true
    }],
  };
};

export const RemoveSectorItem = (quotation, index) => {
  if (index > -1 && index !== undefined) {
    const updatedQuotation = { ...quotation };

    // Check if 'sector' exists in updatedQuotation and it's an array
    if (updatedQuotation.sectors && Array.isArray(updatedQuotation.sectors)) {
      // Create a new array without the item at the specified index
      updatedQuotation.sectors = [...updatedQuotation.sectors.slice(0, index), ...updatedQuotation.sectors.slice(index + 1)];

      // If the 'sector' array is empty, delete it
      if (updatedQuotation.sectors.length === 0) {
        delete updatedQuotation.sectors;
      }

      return updatedQuotation;
    }
  }

  // If index is invalid or 'sector' is not an array, return the original quotation
  return quotation;
};

export const getQuotationSector = (quotation) => (quotation && quotation.sectors) ? quotation.sectors : [];

export const getSectorTotalArea = (quotation) => {
  if (Array.isArray(quotation.sectors) && quotation.sectors.length > 0) {
    return quotation.sectors.reduce((total, item) => {
      if (item && item.area) {
        return total + item.area;
      }
      return total;
    }, 0);
  }

  return 0;
};


export const getSectorTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += quotation.sectors[i].width;
    }
  }
  return total
}

export const getSectorTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += quotation.sectors[i].height;
    }
  }

  return total
}

export const getSectorPriceSqr = (quotation) => {
  if (!quotation || !Array.isArray(quotation.sectors)) {
    return 0;
  }

  return quotation.sectors.reduce((total, sector) => {
    return total + getSectorItemPriceSqr(sector);
  }, 0);
};


export const getSectorAreaTotal = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += getSectorItemAreaTotal(quotation.sectors[i])
    }
  }
  return total
}

export const getSectorItemPriceSqr = (sectorItem) => {
  return sectorItem?.salesPrice ?? 0;
}

export const getSectorItemAreaTotal = (sectorItem) => {
  var totalSector = 0;
  totalSector = getSectorItemPriceSqr(sectorItem) * sectorItem.area;
  return totalSector;
}

export const setSectorItemDimensions = (prevQuotation, index, dimensionType, value) => {
  const updatedQuotation = { ...prevQuotation };
  const sector = { ...updatedQuotation.sectors[index] };
  sector[dimensionType] = parseFloat(value);
  sector.area = sector.width * sector.height;
  updatedQuotation.sectors[index] = sector;
  return updatedQuotation;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getSectorItemCostDescription = (productsData, sectorItem) => {
  return productsData[sectorItem.id].info.name
}

export const getSectorItemAreaCostPrice = (sectorItem) => {
  var costPrice = getSectorItemCostPrice(sectorItem)
  var areaCostPrice = costPrice * sectorItem.area;
  return areaCostPrice
}

export const getSectorItemCostPrice = (sectorItem) => {
  return sectorItem ? sectorItem.costPrice : 0;
}

export const getSectorItemCostTotalWindow = (sectorItem) => {
  var totalWindow = 0;
  totalWindow = getSectorItemAreaCostPrice(sectorItem)
  return totalWindow
}

export const getCostSectorTotalPrice = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += getSectorItemAreaCostPrice(quotation.sectors[i])
    }
  }

  return total
}

export const getCostSectorsTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += quotation.sectors[i].width;
    }
  }
  return total
}


export const getCostSectorsTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.sectors) {
    for (var i = 0; i < quotation.sectors.length; i++) {
      total += quotation.sectors[i].height;
    }
  }
  return total
}

export const getCostSectorTotalArea = (quotation) => {
  var total = 0;
  total = getCostSectorsTotalWidth(quotation) * getCostSectorsTotalHeight(quotation);

  return total
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/

