import {
  IconReport,
} from '@tabler/icons-react';

const reports = {
  permissions: [
    'Admin',
    'Sales',
    'Accountant',
    'Customer Service',
    'Sales Manager',
    'Field Installation Manager',
  ],
  id: 'reports',
  title: 'Reports',
  type: 'group',
  children: [
    {
      id: 'reports',
      title: 'Reports',
      permissions: [
        'Admin',
        'Sales',
        'Accountant',
        'Customer Service',
        'Sales Manager',
        'Field Installation Manager',
      ],
      type: 'item',
      icon: IconReport,
      url: '/reports',
    }
  ]
};

export default reports;
