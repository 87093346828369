import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastMotorItem = (quotation) => {
  if (!quotation.motors?.length) return quotation;
  const newMotorItem = _.cloneDeep(quotation.motors[quotation.motors.length - 1]);
  return {
    ...quotation,
    motors: [...quotation.motors, newMotorItem],
  };
};

export const getQuotationMotorItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and motor section exist
  if (quotation && quotation.motors && Array.isArray(quotation.motors)) {
    // Loop through each item in the motor array
    quotation.motors.forEach((item) => {
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationMotorItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.motors && Array.isArray(quotation.motors)) {
    // Loop through each item in the blinds array
    quotation.motors.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getMotorItemsLength = (quotation) => {
  var length = 0;
  if (quotation.motors) {
    length = quotation.motors.length;
  }
  return length;
};

export const AddMotorItem = (quotation) => {
  return {
    ...quotation,
    motors: [
      ...(quotation.motors || []),
      {
        id: null,
        quantity: 1,
        printable: true,
      },
    ],
  };
};


export const setMotorItem = (quotation, index, productId, productsData) => {
  const productInfo = productsData[productId]?.info || {};
  const updatedMotor = {
    ...quotation.motors[index],
    id: productId,
    salesPrice: productInfo.salesPrice,
    costPrice: productInfo.costPrice
  };
  return {
    ...quotation,
    motors: [
      ...quotation.motors.slice(0, index),
      updatedMotor,
      ...quotation.motors.slice(index + 1)
    ]
  };
};

export function setMotorQuantity(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.motors[index].quantity = parseFloat(value);
  return updatedQuotation;
}

export const RemoveMotorItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1 && updatedQuotation.motors) {
    updatedQuotation.motors.splice(index, 1);

    if (updatedQuotation.motors.length === 0) {
      delete updatedQuotation.motors;
    }
  }

  return updatedQuotation;
};

export const getQuotationMotors = (quotation) => (quotation && quotation.motors) ? quotation.motors : [];

export const getQuotationMotorsTotal = (quotation) => {
  var total = 0;
  if (quotation.motors) {
    for (var i = 0; i < quotation.motors.length; i++) {
      total += getMotorItemTotal(quotation.motors[i])
    }
  }

  return total;
}

export const getMotorsSubTotal = (quotation) => {
  if (Array.isArray(quotation.motors) && quotation.motors.length > 0) {
    return quotation.motors.reduce((total, motor) => {
      if (motor && motor.salesPrice) {
        return total + motor.salesPrice;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getMotorsTotalQuantity = (quotation) => {
  if (Array.isArray(quotation.motors) && quotation.motors.length > 0) {
    return quotation.motors.reduce((total, motor) => {
      if (motor && motor.quantity) {
        return total + motor.quantity;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getMotorItemTotal = (motorItem) => {
  var total = 0;
  var salesPrice = motorItem.id ? motorItem.salesPrice : 0;

  total = (salesPrice ?? 0) * (motorItem.quantity ?? 0);

  return total;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getCostMotorsTotal = (quotation) => {
  var total = 0;
  if (quotation.motors) {
    for (var i = 0; i < quotation.motors.length; i++) {
      total += getCostMotorItemTotal(quotation.motors[i])
    }
  }
  return total
}


export const getMotorItemCostDescription = (productsData, motorItem) => {
  return productsData[motorItem.id].info.name
}

export const getCostMotorItemTotal = (motorItem) => {
  const costPrice = motorItem?.costPrice ?? 0;
  const quantity = motorItem?.quantity ?? 0;
  return costPrice * quantity;
};

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/
