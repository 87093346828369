// assets
import { IconDashboard } from '@tabler/icons-react';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  permissions: [
    'Admin',
    'Sales',
    'Accountant',
    'Customer Service',
    'Sales Manager',
    'Field Installation Manager',
    'Warehouse Supervisor',
    'Purchasing Officer',
  ],
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
    }
  ]
};

export default dashboard;
