import { getServerDateNow, serverTimestampToDate } from 'database/repositories/SystemRepo';
import { format } from 'date-fns';

export const getFirstDateInCurrentMonth = async () => {
  var now = await getServerDateNow();
  var firstDateInMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  firstDateInMonth.setHours(0, 0, 0);
  return firstDateInMonth;
};

export const getFirstDateInLastMonth = async () => {
  const now = await getServerDateNow();
  const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1; // Handle December to January transition
  const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear(); // Handle year change

  const firstDateInLastMonth = new Date(year, lastMonth, 1);
  firstDateInLastMonth.setHours(0, 0, 0, 0); // Set to start of the day
  return firstDateInLastMonth;
};

export const getLastDateInCurrentMonth = async () => {
  var now = await getServerDateNow();

  // Calculate the last day of the current month
  var lastDayDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  var lastDayOfMonth = lastDayDate.getDate();

  // Create the last date in the month using numeric arguments
  var lastDateInMonth = new Date(now.getFullYear(), now.getMonth(), lastDayOfMonth);

  // Set the time to the end of the day
  lastDateInMonth.setHours(23, 59, 59);

  return lastDateInMonth;
}

export const getLastDateInLastMonth = async () => {
  const now = await getServerDateNow();
  const lastMonth = now.getMonth(); // Last month is the current month minus one
  const year = lastMonth === 0 ? now.getFullYear() - 1 : now.getFullYear(); // Handle year change when it's January

  // Setting the day to 0 gives the last day of the previous month
  const lastDayDate = new Date(year, lastMonth, 0);
  lastDayDate.setHours(23, 59, 59); // Set to end of the day

  return lastDayDate;
};

export const timestampToDateString = (timestamp, dateFormat) => {
  var date = timestamp.toDate();
  return format(date, dateFormat);
};

export const getLastHoursInDate = (date) => {
  const modifiedDate = new Date(date)
  modifiedDate.setHours(23, 59, 59)
  return modifiedDate
}

export const getFirstHoursInDate = (date) => {
  const modifiedDate = new Date(date)
  modifiedDate.setHours(0, 0, 0)
  return modifiedDate
}

export const getCurrentDateAndTimeAsString = async () => {
  const date = await getServerDateNow();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

export const getCurrentDateAsString = async () => {
  const date = await getServerDateNow();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day}_${month}_${year}`;
}

export const splitDate = (dateObj) => {
  // Format 1: EEE (Day Name)
  const dayName = dateObj.toLocaleString('en-US', { weekday: 'short' });

  // Format 2: d MMM yyyy (Date)
  const formattedDate = dateObj.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  // Format 3: h:mm a (Time)
  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return { dayName, formattedDate, formattedTime };
}

export const getExcelDateFormat = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const getCurrentMonthName = async () => {
  const monthNames =
    ["January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

  const d = await getServerDateNow();
  return monthNames[d.getMonth()]
}

export function getDateAndTimeSeparate(timestamp) {
  let activityDate = timestamp;
  if (typeof timestamp.toDate === 'function') {
    activityDate = serverTimestampToDate(timestamp);
  } else {
    activityDate = new Date();
  }

  // Define options for formatting the date
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  // Define options for formatting the time
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  // Get formatted date and time
  const formattedDate = activityDate.toLocaleDateString(undefined, dateOptions);
  const formattedTime = activityDate.toLocaleTimeString(undefined, timeOptions);

  // Return as an object
  return {
    date: formattedDate,
    time: formattedTime
  };
}

export function getHumanReadableFormat(timestamp) {

  let activityDate = timestamp;
  if (typeof timestamp.toDate === 'function')
    activityDate = serverTimestampToDate(timestamp);
  else
    activityDate = new Date();

  // Define the options for formatting the date
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  // Format the date and time
  return activityDate.toLocaleString(undefined, options).replace(',', '');
}

export function getFormattedDate(timestamp, useArabicFormat = false) {
  let activityDate = timestamp;

  if (typeof timestamp?.toDate === 'function')
    activityDate = serverTimestampToDate(timestamp);
  else
    activityDate = new Date();

  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const locale = useArabicFormat ? 'ar-EG' : 'en-US';

  return activityDate.toLocaleDateString(locale, options);
}

export function getFormattedDayName(input, useArabicFormat = false) {
  let activityDate;

  // Check if the input is a Firestore Timestamp (has toDate function)
  if (typeof input?.toDate === 'function') {
    activityDate = input.toDate();
  }
  // Check if the input is already a Date object
  else if (input instanceof Date) {
    activityDate = input;
  }
  // If neither, use the current date
  else {
    activityDate = new Date();
  }

  const options = { weekday: 'long' }; // 'weekday' option gives the name of the day
  const locale = useArabicFormat ? 'ar-EG' : 'en-US';

  return activityDate.toLocaleDateString(locale, options);
}
