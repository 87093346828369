import {
  IconDatabase
} from '@tabler/icons-react';

const dataExchange = {
  permissions: [
    'Admin'
  ],
  id: 'dataExchange',
  title: 'Data Exchange ',
  type: 'group',
  children: [
    {
      permissions: [
        'Admin'
      ],
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/data-exchange/products',
      icon: IconDatabase,
    }
  ]
};

export default dataExchange;
