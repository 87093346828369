import {
  IconTarget,
} from '@tabler/icons-react';

const notifications = {
  permissions: [
    'Admin',
    'Sales Manager',
    'Field Installation Manager',
  ],
  id: 'targets',
  title: 'Targets',
  type: 'group',
  children: [
    {
      id: 'sales',
      title: 'Sales',
      permissions: [
        'Admin',
        'Sales Manager',
        'Field Installation Manager',
      ],
      type: 'item',
      icon: IconTarget,
      url: '/targets/sales',
    }
  ]
};

export default notifications;
