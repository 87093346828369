import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastBlindItem = (quotation) => {
  // Check if quotation has any blinds items
  if (!quotation.blinds || quotation.blinds.length === 0) {
    return quotation; // No blinds to copy, return the original quotation
  }

  // Deep clone the last blinds item
  const lastBlindItem = _.cloneDeep(quotation.blinds[quotation.blinds.length - 1]);

  // Modify any properties as needed for the new blinds item
  const newBlindItem = {
    ...lastBlindItem,
  };

  // Return a new quotation with the new blinds item added
  return {
    ...quotation,
    blinds: [...quotation.blinds, newBlindItem],
  };
};


export const getQuotationBlindsItemsNames = (quotation, productsData, itemName) => {
  const uniqueNames = new Set(); // To store unique names

  // Check if quotation and blinds section exist
  if (quotation?.blinds && Array.isArray(quotation.blinds)) {
    // Loop through each item in the blinds array
    quotation.blinds.forEach((item) => {
      let id;

      // Determine the ID based on itemName
      if (itemName === 'motor') {
        id = item.motor?.id; // Only get motor ID if itemName is 'motor'
      } else {
        id = item?.id; // Otherwise, get the item ID
      }

      if (id) {
        const productName = productsData[id]?.info?.name; // Get product name

        if (productName) {
          // Clean up the name if itemName is 'motor'
          const cleanedName = itemName === 'motor'
            ? productName.replace(/(?:\s*\d+\s*Nm|\s*\d+\/\d+\s*Nm)/g, '').trim()
            : productName;

          uniqueNames.add(cleanedName); // Add cleaned name to the set
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getQuotationBlindsItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and blinds section exist
  if (quotation && quotation.blinds && Array.isArray(quotation.blinds)) {
    // Loop through each item in the blinds array
    quotation.blinds.forEach((item) => {
      // Check if the itemName path and fieldName exist
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationBlinds = (quotation) => (quotation && quotation.blinds) ? quotation.blinds : [];

export const getBlindsItemsLength = (quotation) => {
  var length = 0;
  if (quotation.blinds) {
    length = quotation.blinds.length;
  }
  return length;
};

export const getQuotationBlindsTotal = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += getBlindsItemTotal(quotation.blinds[i])
    }
  }

  return total;
}

export const AddBlindItem = (quotation) => {
  return {
    ...quotation,
    blinds: [...(quotation.blinds || []), {
      id: null,
      width: 1,
      height: 1,
      area: 1,
      printable: true
    }],
  };
};

export const RemoveBlindItem = (quotation, index) => {
  if (index > -1 && index !== undefined) {
    const updatedQuotation = { ...quotation };

    // Check if 'blind' exists in updatedQuotation and it's an array
    if (updatedQuotation.blinds && Array.isArray(updatedQuotation.blinds)) {
      // Create a new array without the item at the specified index
      updatedQuotation.blinds = [...updatedQuotation.blinds.slice(0, index), ...updatedQuotation.blinds.slice(index + 1)];

      // If the 'blind' array is empty, delete it
      if (updatedQuotation.blinds.length === 0) {
        delete updatedQuotation.blinds;
      }

      return updatedQuotation;
    }
  }

  // If index is invalid or 'blind' is not an array, return the original quotation
  return quotation;
};

export const getBlindTotalArea = (quotation) => {
  if (Array.isArray(quotation.blinds) && quotation.blinds.length > 0) {
    return quotation.blinds.reduce((total, item) => {
      if (item && item.area) {
        return total + item.area;
      }
      return total;
    }, 0);
  }

  return 0;
};


export const getBlindTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += quotation.blinds[i].width;
    }
  }
  return total
}

export const getBlindTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += quotation.blinds[i].height;
    }
  }

  return total
}

export const getBlindPriceSqr = (quotation) => {
  if (!quotation || !Array.isArray(quotation.blinds)) {
    return 0;
  }

  return quotation.blinds.reduce((total, blind) => {
    return total + getBlindItemPriceSqr(blind);
  }, 0);
};


export const getBlindAreaTotal = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += getBlindItemAreaTotal(quotation.blinds[i])
    }
  }
  return total
}

export const getBlindItemPriceSqr = (blindItem) => {
  return blindItem?.salesPrice ?? 0;
}

export const getBlindItemAreaTotal = (blindItem) => {
  const area = blindItem.area < 1.5 ? 1.5 : blindItem.area;  // Ensure area is at least 1.5
  const totalBlind = getBlindItemPriceSqr(blindItem) * area;
  return totalBlind;
};

export const setBlindItemDimensions = (prevQuotation, index, dimensionType, value) => {
  const updatedQuotation = { ...prevQuotation };
  const blind = { ...updatedQuotation.blinds[index] };
  blind[dimensionType] = parseFloat(value);
  blind.area = blind.width * blind.height;
  updatedQuotation.blinds[index] = blind;
  return updatedQuotation;
};

export const setBlindItemMotor = (prevQuotation, index, motorId, productsData) => {
  const updatedQuotation = { ...prevQuotation };

  if (motorId === undefined) {
    // Set motor to null if motorId is undefined
    updatedQuotation.blinds[index].motor = null;
  } else {
    // Otherwise, update motor with the given motorId
    updatedQuotation.blinds[index].motor = updatedQuotation.blinds[index].motor || {};
    updatedQuotation.blinds[index].motor.id = motorId;

    if (productsData[motorId]) {
      updatedQuotation.blinds[index].motor.costPrice = productsData[motorId].info.costPrice;
      updatedQuotation.blinds[index].motor.salesPrice = productsData[motorId].info.salesPrice;
    } else {
      console.warn(`Motor product data for ID ${motorId} not found.`);
    }
  }

  return updatedQuotation;
};

export const getBlindsTotalMotors = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      if (quotation.blinds[i].motor) {
        total += quotation.blinds[i].motor.salesPrice
      }
    }
  }
  return total
}

export const getBlindsItemTotal = (blindItem) => {
  var total = 0;
  var motorPrice = 0;
  if (blindItem.motor) {
    motorPrice = blindItem.motor.salesPrice;
  }

  total = getBlindItemAreaTotal(blindItem) + motorPrice
  return total
}

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/


export const getCostBlindsTotal = (quotation) => {
  var total = 0;
  total += getCostBlindTotalPrice(quotation)
  total += getCostBlindTotalMotorPrice(quotation)
  return total
}

export const getBlindItemCostPrice = (blindItem) => {
  return blindItem?.costPrice ?? 0;

}

export const getBlindItemAreaCostPrice = (blindItem) => {
  var costPrice = getBlindItemCostPrice(blindItem)
  var areaCostPrice = costPrice * blindItem.area;
  return areaCostPrice
}

export const getBlindItemCostDescription = (productsData, blindItem) => {
  const blindName = productsData[blindItem.id]?.info?.name || '';
  const motorName = blindItem.motor
    ? productsData[blindItem.motor.id]?.info?.name || ''
    : 'Manual';

  return `${blindName} / ${motorName}`;
};

export const getBlindItemCostTotalWindow = (blindItem) => {
  var totalWindow = 0;
  if (blindItem.motor) {
    totalWindow = blindItem.motor.costPrice + getBlindItemAreaCostPrice(blindItem)
  }
  else {
    totalWindow = getBlindItemAreaCostPrice(blindItem)
  }
  return totalWindow
}


export const getCostBlindTotalWidth = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += quotation.blinds[i].width;
    }
  }

  return total
}

export const getCostBlindTotalHeight = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += quotation.blinds[i].height;
    }
  }
  return total
}

export const getCostBlindTotalArea = (quotation) => {
  var total = 0;
  total = getCostBlindTotalWidth(quotation) * getCostBlindTotalHeight(quotation);
  return total
}

export const getCostBlindTotalPrice = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += getBlindItemAreaCostPrice(quotation.blinds[i])
    }
  }
  return total
}

export const getCostBlindTotalMotorPrice = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      if (quotation.blinds[i].motor) {
        total += quotation.blinds[i].motor.costPrice
      }
    }
  }
  return total
}

export const getCostBlindsTotalOfTotalWindow = (quotation) => {
  var total = 0;
  if (quotation.blinds) {
    for (var i = 0; i < quotation.blinds.length; i++) {
      total += getBlindItemCostTotalWindow(quotation.blinds[i])
    }
  }
  return total
}


/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/

export const setJobOrderBlindsNote = (quotation, index, value) => ({
  ...quotation,
  blinds: getQuotationBlinds(quotation).map((blind, i) =>
    i === index ? { ...blind, note: value } : blind
  ),
});

export const getJobOrderBlindNote = (blindItem) => {
  var note = ''
  if (blindItem.note) {
    note = note.concat(blindItem.note)
  }
  return note
}

export const getJobOrderBlindsDescription = (productsData, blindItem) => {
  let description = '';

  if (blindItem) {
    const { id, colorName } = blindItem;
    description = `${productsData[id]?.info.name || ''} ${colorName || ''}`;
  }

  if (blindItem.motor) {
    const { id } = blindItem.motor;
    description = description.concat(' ', productsData[id]?.info.name || '');
  }

  return description;
};

export const getJobOrderBlindsDescriptionPDF = (productsData, blindsItem) => {
  let description = '';

  if (blindsItem.sector) {
    const { id, colorName } = blindsItem.sector;
    description = `${productsData[id]?.info.name || ''} ${colorName || ''}`;
  }

  if (blindsItem.motor && blindsItem.printMotor) {
    const { id } = blindsItem.motor;
    description = description.concat(' ', productsData[id]?.info.name || '');
  }

  return description;
};



export const getJobOrderBlindPrintMotor = (blindItem) => !!blindItem.printMotor;