import React, { useContext, useRef, useState } from 'react';
import { AuthContext } from 'database/providers/AuthProvider';
import { Popover, Typography, Link, Divider, Box, Stack, useTheme, Chip } from '@mui/material';
import { styles } from './styles'
import SubCard from 'panel/src/views/cards/SubCard';
import { IconLogout, IconMail, IconPhone } from '@tabler/icons-react';
import { enqueueSnackbar } from 'notistack';
import { updateUser, uploadUserProfilePicture } from 'database/repositories/UsersRepo';
import ProfilePictureAvatar from './ProfilePictureAvatar';

const ProfileSection = () => {
  const { globalProfile, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };


  const handleOnChangeProfilePictureClicked = () => {
    fileInputRef.current.click(); // Open file dialog
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (max 2 MB)
      if (file.size > 2 * 1024 * 1024) {
        enqueueSnackbar('Image size should not exceed 2 MB', { variant: 'error' });
        return;
      }

      // Check aspect ratio 1:1
      const image = new Image();
      image.onload = async () => {
        const aspectRatio = image.width / image.height;
        if (aspectRatio !== 1) {
          enqueueSnackbar('Please upload an image with a 1:1 aspect ratio', { variant: 'error' });
        } else {
          setUploading(true);
          try {
            const imageRef = await uploadUserProfilePicture(globalProfile.id, file);
            globalProfile.profilePicture = imageRef;
            await updateUser(globalProfile);
            enqueueSnackbar('Profile Picture Updated Successfully', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Failed to update profile picture', { variant: 'error' });
          } finally {
            setUploading(false);
          }
        }
      };
      image.src = URL.createObjectURL(file);
    }
  };

  const renderProfileTag = () => {
    return (
      <Stack
        sx={{
          borderLeft: `1px solid ${theme.palette.secondary.dark} !important`,
          px: 2,
          marginLeft: '20px !important',
        }}
        direction={'row'} spacing={2}>

        <Box onClick={handleClick}>
          <ProfilePictureAvatar
            src={globalProfile?.profilePicture || null}
            variant="rounded"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '15px',
            }}
          />
        </Box>
        <Stack direction={'column'}>
          <Typography variant='body1' color={theme.palette.grey[700]}>{globalProfile?.name}</Typography>
        </Stack>
      </Stack>
    )
  }

  const renderProfilePopover = () => {
    return (
      <Popover
        style={styles.popper}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}>
        <SubCard
          sx={{ background: `${theme.palette.background.default}80` }}
          title={
            <Stack
              alignItems={'center'}
              direction={'column'}>

              <ProfilePictureAvatar
                onClick={handleOnChangeProfilePictureClicked}
                variant="rounded"
                loading={uploading}
                src={globalProfile?.profilePicture}
                sx={{
                  mb: 2,
                  width: '180px',
                  height: '180px',
                  cursor: 'pointer',
                }}
              />
              <Typography variant='body1' color={theme.palette.grey[700]}>{globalProfile?.name}</Typography>
              <Typography
                sx={{
                  opacity: uploading ? '0' : '100%',
                  mb: 2,
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                variant="caption"
                onClick={handleOnChangeProfilePictureClicked}
              >
                Change profile picture
              </Typography>


              <Box sx={{ display: 'flex', gap: 0.5 }}>
                {globalProfile?.permissions.map((permission, index) =>
                  <Chip size={'small'} key={index} label={permission} />
                )}
              </Box>
            </Stack>
          }>

          <Stack direction={'column'} spacing={1} sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconMail size={20} color={theme.palette.primary.main} />
              <Typography variant="body2">
                {globalProfile?.email}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconPhone size={20} color={theme.palette.primary.main} />
              <Typography variant="body2">
                {globalProfile?.phoneNumber}
              </Typography>
            </Box>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Box
            onClick={() => { logout() }}
            sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
            <IconLogout size={20} color={theme.palette.primary.main} />
            <Typography variant="body2">
              Logout
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={styles.termsAndConditions}>
            <Link
              variant='caption'
              href="https://evo-shutter.app/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>

            <Link
              variant='caption'
              href="https://evo-shutter.app/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </Box>
        </SubCard>
      </Popover>
    )
  }

  return (
    <React.Fragment>
      {renderProfileTag()}
      {renderProfilePopover()}

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hidden input
      />

    </React.Fragment >
  );
};

export default ProfileSection;
