import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastBoxItem = (quotation) => {
  if (!quotation.boxes?.length) return quotation;
  const newBoxItem = _.cloneDeep(quotation.boxes[quotation.boxes.length - 1]);
  return {
    ...quotation,
    boxes: [...quotation.boxes, newBoxItem],
  };
};

export const getQuotationBoxItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and box section exist
  if (quotation && quotation.boxes && Array.isArray(quotation.boxes)) {
    // Loop through each item in the box array
    quotation.boxes.forEach((item) => {
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationBoxItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.boxes && Array.isArray(quotation.boxes)) {
    // Loop through each item in the blinds array
    quotation.boxes.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getBoxItemsLength = (quotation) => {
  var length = 0;
  if (quotation.boxes) {
    length = quotation.boxes.length;
  }
  return length;
};

export const AddBoxItem = (quotation) => {
  return {
    ...quotation,
    boxes: [
      ...(quotation.boxes || []),
      {
        id: null,
        quantity: 1,
        multiplier: 1,
        printable: true,
      },
    ],
  };
};


export const setBoxItem = (quotation, index, productId, productsData) => {
  const productPricing = productsData[productId]?.info?.pricing[0] || {};
  const updatedBox = {
    ...quotation.boxes[index],
    id: productId,
    colorName: productPricing.colorName,
    colorValue: productPricing.colorValue,
    costPrice: productPricing.costPrice,
    salesPrice: productPricing.salesPrice,
    size: productPricing.size,
  };
  return {
    ...quotation,
    boxes: [
      ...quotation.boxes.slice(0, index),
      updatedBox,
      ...quotation.boxes.slice(index + 1)
    ]
  };
};

export function setBoxQuantity(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.boxes[index].quantity = parseFloat(value);
  return updatedQuotation;
}

export function setBoxMultiplier(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.boxes[index].multiplier = parseFloat(value);
  return updatedQuotation;
}

export function setBoxPricing(quotation, index, pricingData) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.boxes[index] = { ...updatedQuotation.boxes[index], ...pricingData };
  return updatedQuotation;
}

export const RemoveBoxItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1 && updatedQuotation.boxes) {
    updatedQuotation.boxes.splice(index, 1);

    if (updatedQuotation.boxes.length === 0) {
      delete updatedQuotation.boxes;
    }
  }

  return updatedQuotation;
};

export const getQuotationBoxes = (quotation) => (quotation && quotation.boxes) ? quotation.boxes : [];

export const getQuotationBoxesTotal = (quotation) => {
  var total = 0;
  if (quotation.boxes) {
    for (var i = 0; i < quotation.boxes.length; i++) {
      total += getBoxItemTotal(quotation.boxes[i])
    }
  }

  return total;
}

export const getBoxesSubTotal = (quotation) => {
  if (Array.isArray(quotation.boxes) && quotation.boxes.length > 0) {
    return quotation.boxes.reduce((total, box) => {
      if (box && box.salesPrice) {
        return total + box.salesPrice;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getBoxesTotalMultiplier = (quotation) => {
  if (Array.isArray(quotation.boxes) && quotation.boxes.length > 0) {
    return quotation.boxes.reduce((total, box) => {
      if (box && box.multiplier) {
        return total + box.multiplier;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getBoxesTotalQuantity = (quotation) => {
  if (Array.isArray(quotation.boxes) && quotation.boxes.length > 0) {
    return quotation.boxes.reduce((total, box) => {
      if (box && box.quantity) {
        return total + box.quantity;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getBoxItemTotal = (boxItem) => {
  var total = 0;
  var salesPrice = boxItem.id ? boxItem.salesPrice : 0;

  total = (salesPrice ?? 0) * (boxItem.quantity ?? 0) * (boxItem.multiplier ?? 0);

  return total;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/


export const getCostBoxesTotal = (quotation) => {
  var total = 0;
  if (quotation.boxes) {
    for (var i = 0; i < quotation.boxes.length; i++) {
      total += getCostBoxItemTotal(quotation.boxes[i])
    }
  }

  return total
}


export const getBoxItemCostDescription = (productsData, boxItem) => {
  return productsData[boxItem.id].info.name
}

export const getCostBoxItemTotal = (boxItem) => {
  const costPrice = boxItem?.costPrice ?? 0;
  const quantity = boxItem?.quantity ?? 0;
  return costPrice * quantity;
};


/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/

