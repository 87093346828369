// material-ui
import { useTheme } from '@mui/material/styles';
import { IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Brightness2, Brightness3 } from '@mui/icons-material';

// assets
import { SET_THEME_MODE } from 'panel/src/store/actions';

const ThemeModeSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const mode = localStorage.getItem('colorMode') || (prefersDarkMode ? 'dark' : 'light') || 'light';
  const tooltipTitle = theme.palette.mode === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode';

  const handleToggle = () => {
    dispatch({ type: SET_THEME_MODE, mode: mode === 'dark' ? 'light' : 'dark' });
    const root = document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(mode === 'dark' ? 'light' : 'dark');
  }

  return (
    <Tooltip title={tooltipTitle} arrow>
      <IconButton
        sx={{
          backgroundColor: theme.palette.secondary.light,
        }}
        onClick={handleToggle}>
        {theme.palette.mode === 'dark' ? <Brightness2 /> : <Brightness3 />}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeModeSection;
