import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ErrorSnackbar, NotificationSnack, SuccessSnackbar, WarningSnackbar } from 'components/extended/CustomSnack';

import Routes from 'panel/cfg/routes';
import themes from 'panel/cfg/themes';

import AuthProvider from 'database/providers/AuthProvider';
import NetworkGuard from 'guards/NetworkGuard';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  const root = document.documentElement;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const mode = localStorage.getItem('colorMode') || (prefersDarkMode ? 'dark' : 'light') || 'light';
  root.classList.remove('light', 'dark');
  root.classList.add(mode);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization, prefersDarkMode)}>
        <CssBaseline />
        <NetworkGuard>
          <AuthProvider>
            <SnackbarProvider
              maxSnack={3}
              Components={{
                success: SuccessSnackbar,
                error: ErrorSnackbar,
                warning: WarningSnackbar,
                notification: NotificationSnack
              }}>
              <Routes />
            </SnackbarProvider>
          </AuthProvider>
        </NetworkGuard>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
