import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const EmptyLayout = ({ icon: IconComponent, title, message, button, orientation }) => {
  const navigate = useNavigate();

  const handleButtonClicked = (event) => {
    if (button.type === 'navigate') {
      navigate(button.link);
    }
    else {
      button.action(event);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: orientation === 'start' ? 'flex-start' : 'center',
        padding: '50px',
        borderRadius: '15px',
        maxWidth: 600,
        margin: 'auto',
      }}
    >
      <IconComponent size={100} stroke={1} />
      <Typography variant="h5" sx={{ mt: 3, mb: 2, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, fontSize: 15, textAlign: orientation === 'start' ? 'flex-start' : 'center' }}>
        {message}
      </Typography>
      {button && (
        <Button
          disabled={!button?.editable}
          variant="outlined"
          color="primary"
          onClick={handleButtonClicked}
          sx={{ textTransform: 'none' }}
        >
          {button.title}
        </Button>
      )}
    </Box>
  );
};

export default EmptyLayout;
