import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastTrackItem = (quotation) => {
  if (!quotation.tracks?.length) return quotation;
  const newTrackItem = _.cloneDeep(quotation.tracks[quotation.tracks.length - 1]);
  return {
    ...quotation,
    tracks: [...quotation.tracks, newTrackItem],
  };
};

export const getQuotationTrackItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and track section exist
  if (quotation && quotation.tracks && Array.isArray(quotation.tracks)) {
    // Loop through each item in the track array
    quotation.tracks.forEach((item) => {
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationTrackItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.tracks && Array.isArray(quotation.tracks)) {
    // Loop through each item in the blinds array
    quotation.tracks.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getTrackItemsLength = (quotation) => {
  var length = 0;
  if (quotation.tracks) {
    length = quotation.tracks.length;
  }
  return length;
};

export const AddTrackItem = (quotation) => {
  return {
    ...quotation,
    tracks: [
      ...(quotation.tracks || []),
      {
        id: null,
        multiplier: 1,
        quantity: 1,
        printable: true,
      },
    ],
  };
};


export const setTrackItem = (quotation, index, productId, productsData) => {
  const productPricing = productsData[productId]?.info?.pricing[0] || {};
  const updatedTrack = {
    ...quotation.tracks[index],
    id: productId,
    colorName: productPricing.colorName,
    colorValue: productPricing.colorValue,
    costPrice: productPricing.costPrice,
    salesPrice: productPricing.salesPrice
  };
  return {
    ...quotation,
    tracks: [
      ...quotation.tracks.slice(0, index),
      updatedTrack,
      ...quotation.tracks.slice(index + 1)
    ]
  };
};

export function setTrackMultiplier(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.tracks[index].multiplier = parseFloat(value);
  return updatedQuotation;
}

export function setTrackQuantity(quotation, index, value) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.tracks[index].quantity = parseFloat(value);
  return updatedQuotation;
}

export function setTrackPricing(quotation, index, pricingData) {
  const updatedQuotation = { ...quotation };
  updatedQuotation.tracks[index] = { ...updatedQuotation.tracks[index], ...pricingData };
  return updatedQuotation;
}

export const RemoveTrackItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1 && updatedQuotation.tracks) {
    updatedQuotation.tracks.splice(index, 1);

    if (updatedQuotation.tracks.length === 0) {
      delete updatedQuotation.tracks;
    }
  }

  return updatedQuotation;
};

export const getQuotationTracks = (quotation) => (quotation && quotation.tracks) ? quotation.tracks : [];

export const getQuotationTracksTotal = (quotation) => {
  var total = 0;
  if (quotation.tracks) {
    for (var i = 0; i < quotation.tracks.length; i++) {
      total += getTrackItemTotal(quotation.tracks[i])
    }
  }

  return total;
}

export const getTracksSubTotal = (quotation) => {
  if (Array.isArray(quotation.tracks) && quotation.tracks.length > 0) {
    return quotation.tracks.reduce((total, track) => {
      if (track && track.salesPrice) {
        return total + track.salesPrice;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getTracksTotalMultiplier = (quotation) => {
  if (Array.isArray(quotation.tracks) && quotation.tracks.length > 0) {
    return quotation.tracks.reduce((total, track) => {
      if (track && track.multiplier) {
        return total + track.multiplier;
      }
      return total;
    }, 0);
  }

  return 0;
};


export const getTracksTotalQuantity = (quotation) => {
  if (Array.isArray(quotation.tracks) && quotation.tracks.length > 0) {
    return quotation.tracks.reduce((total, track) => {
      if (track && track.quantity) {
        return total + track.quantity;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getTrackItemTotal = (trackItem) => {
  var total = 0;
  var salesPrice = trackItem.id ? trackItem.salesPrice : 0;

  total = (salesPrice ?? 0) * (trackItem.quantity ?? 0);

  return total;
};


/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getTrackItemCostDescription = (productsData, trackItem) => {
  return productsData[trackItem.id].info.name
}

export const getCostTrackItemTotal = (trackItem) => {
  const costPrice = trackItem?.costPrice ?? 0;
  const quantity = trackItem?.quantity ?? 0;
  return costPrice * quantity;
};

export const getCostTracksTotal = (quotation) => {
  var total = 0;
  if (quotation.tracks) {
    for (var i = 0; i < quotation.tracks.length; i++) {
      total += getCostTrackItemTotal(quotation.tracks[i])
    }
  }
  return total
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/