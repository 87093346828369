import {
  firestoreGetObjectByID,
  firestoreSetObject,
  firestoreGetCollectionCount,
  firestoreGetCollection,
  registerCollectionListener,
  firestoreGetPushKey,
} from 'database/firebase/api/database'

import {
  firebaseStorageDeleteFile,
  firebaseStorageUploadRandomNameFile
} from 'database/firebase/api/storage'

import {
  usePagination
} from 'database/firebase/hooks/usePagination'

export const attachProductsListener = async (callback) => {
  return await registerCollectionListener(`products`, callback)
}

export const getProductPushID = () => {
  return new Promise(async (resolve, reject) => {
    await firestoreGetPushKey('products')
      .then((data) => {
        console.log('getProductPushID success')
        resolve(data);
      })
      .catch((error) => {
        console.log('getProductPushID Fail')
        reject(error);
      });
  });
}

export const addNewProduct = (product) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject(`products/${product.id}`, product)
      .then((data) => {
        console.log('addNewProduct success')
        resolve(data);
      })
      .catch((error) => {
        console.log('addNewProduct Fail')
        reject(error);
      });
  });
}

export const usePaginateProducts = (mode, filter) => {
  const path = 'products';
  let filters = [];
  if (mode === 'all') {
    filters = [];
  }
  else if (mode === 'type') {
    filters = [
      {
        key: 'type',
        op: '==',
        value: filter,
      },
    ];
  }
  else if (mode === 'search') {
    filters = [
      {
        'key': 'searchKeys',
        'op': 'array-contains-any',
        'value': [filter]
      }
    ];
  }
  const orders = [
    {
      key: 'created',
      value: 'desc',
    },
  ];
  const limit = 10;

  const { getFirstPage, loadMore, hasMore, documents, loading } = usePagination(
    path,
    filters,
    orders,
    limit,
  );

  return {
    getFirstPage,
    loadMore,
    hasMore,
    products: documents,
    loading,
  };
};

export const getProductbyID = (productID) => {
  return new Promise((resolve, reject) => {
    firestoreGetObjectByID('products/' + productID)
      .then((data) => {
        console.log('getProductbyID success')
        resolve(data);
      })
      .catch((error) => {
        console.log('getProductbyID Fail')
        reject(error);
      });
  });
}

export const updateProduct = (product) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject(`products/${product.id}`, product)
      .then((product) => {
        console.log('updateProduct success')
        resolve(product);
      })
      .catch((error) => {
        console.log('updateProduct Fail')
        reject(error);
      });
  });
}

export const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    const path = 'products'
    const filters = []
    const orders = []
    const limit = 0

    firestoreGetCollection(
      path,
      filters,
      orders,
      limit,
    ).then((records) => {
      console.log('getAllProducts success')
      resolve(records);

    }).catch((error) => {
      console.log('getAllProducts Fail')
      reject(error);
    });
  });
}

export const getProductsCount = () => {
  return new Promise((resolve, reject) => {
    const path = 'products'
    const filters = []
    const orders = []

    firestoreGetCollectionCount(
      path,
      filters,
      orders,
    ).then((records) => {
      console.log('getProductsCount success')
      resolve(records);

    }).catch((error) => {
      console.log('getProductsCount Fail')
      reject(error);
    });
  });
}

export const uploadProductDoc = (productID, file) => {
  return new Promise((resolve, reject) => {
    firebaseStorageUploadRandomNameFile(`products/${productID}/`, file)
      .then((data) => {
        console.log('uploadProductDoc success')
        resolve(data);
      })
      .catch((error) => {
        console.log('uploadProductDoc Fail')
        reject(error);
      });
  });
}

export const deleteProductDoc = (productDocUrl) => {
  return new Promise((resolve, reject) => {
    firebaseStorageDeleteFile(productDocUrl)
      .then((data) => {
        console.log('deleteProductDoc success')
        resolve(data);
      })
      .catch((error) => {
        console.log('deleteProductDoc Fail')
        reject(error);
      });
  });
}