import { addNewNotificaion } from "database/repositories/NotificationsRepo"
import { isTechnicalUser } from "./permissions"


export const SupportedUserNotificationRecipients = [
  'User',
  'Customer'
]

export const TargetAudiance = [
  'Broadcast',
  'Group',
  'Specific'
]

export const NotificationTemplates = [
  'Order',
  'System'
]

export const SupportedUserNotificationChannels = [
  // 'WhatsApp',
  'Browser',
  // 'Email'
]

export const SupportedCustomerNotificationChannels = [
  // 'WhatsApp',
  // 'Email',
  // 'SMS'
]

export const initNotification = (globalProfile) => {
  var notification = {
    recipientType: null,
    target: null,
    filters: [],
    title: null,
    template: 'System',
    url: null,
    senderId: globalProfile.id,
    status: 'ready',
    body: null,
    channels: null,

  }
  return notification;
}

export const isNotificationValid = (notification) => {

  if (!notification.recipientType) {
    return false;
  }

  if (!notification.title) {
    return false;
  }

  if (!notification.body) {
    return false;
  }

  // Validate that channels exist and have at least one valid key with status "ready"
  if (!Array.isArray(notification.channels) || notification?.channels?.length <= 0) {
    return false;
  }

  // If all checks pass, return true
  return true;
};

export const getNotificationValue = (notification, key) => {
  const keys = key.split('.'); // Split the key by dots
  let value = notification;

  for (const k of keys) {
    if (value && value.hasOwnProperty(k)) {
      value = value[k]; // Navigate deeper into the object
    } else {
      return null; // Return null if the key path does not exist
    }
  }
  return value; // Return the found value
};

export const setNotificationValue = (notification, key, value) => {
  const keys = key.split('.'); // Split the key by dots
  const updatedNotification = { ...notification };
  let current = updatedNotification;

  // Check if the key is 'recipientType' and the new value differs from the current value
  if (keys[0] === 'recipientType' && value !== notification.recipientType) {
    // Clear target and reset target to an empty object
    updatedNotification.target = null;
    updatedNotification.filters = [];
    updatedNotification.channels = null;
  }

  // Traverse the object structure to the second last key
  for (let i = 0; i < keys.length - 1; i++) {
    const k = keys[i];
    // Create the nested object if it doesn't exist
    if (!current[k]) {
      current[k] = {};
    }
    current = current[k]; // Move deeper
  }

  // Set the value for the last key
  current[keys[keys.length - 1]] = value;

  return updatedNotification; // Return the updated object
};

export const setNotificationFilters = (notification, key, op, value) => {
  const updatedNotification = { ...notification };

  // Check if filters array exists, if not create it
  if (!updatedNotification.filters) {
    updatedNotification.filters = [];
  }

  // Find if a filter with the same key already exists
  const existingFilterIndex = updatedNotification.filters.findIndex(filter => filter.key === key);

  // If it exists, update the filter's operation and value
  if (existingFilterIndex !== -1) {
    updatedNotification.filters[existingFilterIndex] = { key, op, value };
  } else {
    // If it doesn't exist, add a new filter object to the filters array
    updatedNotification.filters.push({ key, op, value });
  }

  return updatedNotification; // Return the updated object
};

// Function to notify Admins and Sales Managers
export const notifyAdminsAndSalesManagers = async (order, globalProfile, historyItem, action) => {
  const notification = {
    recipientType: 'User',
    target: 'Group',
    filters: [
      {
        key: 'permissions',
        op: 'array-contains-any',
        value: ['Admin', 'Sales Manager'],
      },
    ],
    title: `Order ${order.code} Status Change`,
    template: 'Order',
    url: order.id,
    status: 'ready',
    senderId: globalProfile.id,
    body: `${globalProfile.name} ${action} order ${order.code} status to ${historyItem.status}`,
    channels: ['Browser'],
  };

  if (!isTechnicalUser(globalProfile)) {
    await addNewNotificaion(notification);
  }
};


// Function to notify Admins and Sales Managers
const notifyWarehouseSupervisors = async (order, globalProfile, historyItem, action) => {

  const eligibleStatuses = ['Pending Delivery', 'In Stock', 'Ready', 'Delivered', 'Installed'];

  // Check if the current status is eligible
  if (!eligibleStatuses.includes(historyItem.status.toLowerCase())) {
    return;
  }

  const notification = {
    recipientType: 'User',
    target: 'Group',
    filters: [
      {
        key: 'permissions',
        op: 'array-contains-any',
        value: ['Warehouse Supervisor'],
      },
    ],
    title: `Order ${order.code} Status Change`,
    template: 'Order',
    url: order.id,
    status: 'ready',
    senderId: globalProfile.id,
    body: `${globalProfile.name} ${action} order ${order.code} status to ${historyItem.status}`,
    channels: ['Browser'],
  };

  if (!isTechnicalUser(globalProfile)) {
    await addNewNotificaion(notification);
  }
};


// Function to notify the sales user assigned to the order
const notifySalesUserAssignedToOrder = async (order, globalProfile, historyItem, action) => {

  const notification = {
    recipientType: 'User',
    target: 'Specific',
    filters: [
      {
        key: 'id',
        op: '==',
        value: order.userID,
      },
    ],
    title: `Order ${order.code} Status Change`,
    template: 'Order',
    url: order.id,
    status: 'ready',
    senderId: globalProfile.id,
    body: `${globalProfile.name} ${action} order ${order.code} status to ${historyItem.status}`,
    channels: ['Browser'],
  };

  if (!isTechnicalUser(globalProfile)) {
    await addNewNotificaion(notification);
  }
};

// Main function to build notifications
export const buildOrderStatusChangeNotifications = async (order, globalProfile, historyItem, action) => {
  await notifyAdminsAndSalesManagers(order, globalProfile, historyItem, action);
  await notifyWarehouseSupervisors(order, globalProfile, historyItem, action);
  await notifySalesUserAssignedToOrder(order, globalProfile, historyItem, action);

};