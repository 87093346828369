// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { databaseConfig } from 'panel/cfg/database/confg';
import { Fragment } from 'react';

const MenuList = () => {
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <Fragment key={item.id}>
            {databaseConfig.isAuthorized(item.permissions, <NavGroup item={item} />)}
          </Fragment>
        );

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;