import { getServerTimestampNow } from "database/repositories/SystemRepo";
import { isUserAuthorized } from "../permissions";
import { getQuotationRemainingAmount, quotationValid } from "./quotation";
import { buildOrderStatusChangeNotifications } from "model/Notification";

export const setOrderStatus = async (order, globalProfile, status) => {
  const updatedOrder = { ...order };
  updatedOrder.status = status;
  updatedOrder.history = updatedOrder.history || [];

  const historyItem = {
    created: await getServerTimestampNow(),
    reference: globalProfile.id,
    status: status
  };

  updatedOrder.history.push(historyItem);
  updatedOrder.historyDates = updatedOrder.historyDates || {}
  updatedOrder.historyDates[status] = historyItem.created

  await buildOrderStatusChangeNotifications(order, globalProfile, historyItem, 'changed');

  return updatedOrder;
};

export const addNewOrderMaintenance = (maintenanceOrderID, order) => {
  const updatedOrder = { ...order };
  updatedOrder.maintenances = updatedOrder.maintenances || [];
  updatedOrder.maintenances.push(maintenanceOrderID);
  return updatedOrder;
};
export const isMaintenanceOrder = (order) => {
  return order.type === 'maintenance'
}

export const isQuotationCreated = (order) => {
  if (order?.quotation) {
    const result = quotationValid(order.quotation);
    return result.success;
  }
  return false;
};

export const orderHasPayment = (order) => {
  if (Array.isArray(order?.quotation?.payment) && order.quotation.payment.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getOrderNewMaintenanceNo = (order) => {
  if (Array.isArray(order.maintenances)) {
    return order.maintenances.length + 1;
  } else {
    return 1;
  }
};

export const getOrderMaintenancesOrdersIds = (order) => {
  return order?.maintenances?.length ? order.maintenances : null;
};

export const isOrderReadyForCreation = (order) => {
  return order.customerID && order.areaID && order.addressID;
}

// Utility function to check if a document exists and is a non-empty array
const isDocumentUploaded = (order, documentName) => {
  const documentArray = order?.documents?.[documentName];
  return Array.isArray(documentArray) && documentArray.length > 0;
};

export const isDocumentManufacturingInspectionUploaded = (order) => {
  return isDocumentUploaded(order, 'Manufacturing Inspection');
};

export const isDocumentManufacturingApprovalDeclarationUploaded = (order) => {
  return isDocumentUploaded(order, 'Manufacturing Approval Declaration');
};

export const isDocumentFactoryCostSpecificationUploaded = (order) => {
  return isDocumentUploaded(order, 'Factory Cost Specification');
};
export const isDocumentFactoryReceiptSpecificationUploaded = (order) => {
  return isDocumentUploaded(order, 'Factory Receipt Specification');
};

export const isDocumentAssemblyRequestDocumentUploaded = (order) => {
  return isDocumentUploaded(order, 'Assembly Request Document');
};

export const isDocumentGoodsDeliveryDeclarationUploaded = (order) => {
  return isDocumentUploaded(order, 'Goods Delivery Declaration');
};

export const isDocumentDeliveryReportUploaded = (order) => {
  return isDocumentUploaded(order, 'Delivery Report');
};

export const isOrderInstalled = (order) => {
  return order?.status === 'Installed'
}

export const isTechniciansBonusAdded = (order) => {
  return Array.isArray(order?.quotation?.cost?.labors) && order.quotation.cost.labors.length > 0;
};

export const isOrderPaidFull = (order) => {
  if (order?.quotation) {
    // Get the remaining amount from the quotation and convert to an integer
    const remainingAmount = Math.floor(getQuotationRemainingAmount(order.quotation));

    // Consider the order as paid if remainingAmount is less than or equal to 50 (including negative values)
    return remainingAmount <= 50;
  }
  return false; // Return false if there is no quotation
};

export const quotationIncludes = (quotation, item) => {
  return (
    quotation &&
    Array.isArray(quotation[item]) &&
    quotation[item].length > 0
  );
};

export const updateOrderReference = (order, newID) => ({
  ...order,
  userID: newID
});


export const quotationIncludesPrintable = (quotation, item) => {
  return (
    quotation &&
    Array.isArray(quotation[item]) &&
    quotation[item].some((item) => item.printable)
  );
};

export const getOrderStatusDate = (order, status) => {
  if (order.history) {
    const statusEntry = order.history.find(entry => entry.status === status);
    return statusEntry ? statusEntry.created : null;
  }
  return null;
};


export const isOrderEditable = (order, globalProfile) => {
  const authorized = isUserAuthorized(globalProfile, ['Admin', 'Sales Manager', 'Field Installation Manager']);
  const salesAuthorized = isUserAuthorized(globalProfile, ['Sales']);

  // Determine if the order is editable
  let editable = false;

  if (authorized) {
    // Admin, Sales Manager, Field Installation Manager can edit at any time, any status
    editable = true;
  } else if (salesAuthorized) {
    // Sales user can edit only if the status is "Offer"
    editable = order.status === "Offer";
  }

  return editable;
};

export const getOrderStatusItemByValue = (value) => {
  return OrderStatus.find((status) => status.value === value);
};

export const getOrderStatusItemByUrl = (url) => {
  return OrderStatus.find((status) => status.url === url);
};

export const getOrderStatusIndexByValue = (value) => {
  return OrderStatus.findIndex((status) => status.value === value);
};

export const isOrderStatusConditionsMet = (order, statusItem) => {
  if (!statusItem || !statusItem.conditions) {
    return false; // No conditions to check if statusItem is not found
  }

  // Check if all conditions are met
  return statusItem.conditions.every(condition => condition.function(order));
};

export const getStatusInOrderHistory = (order, status) => {
  return order.history ? order.history.find(item => item.status === status) || null : null;
};

export const revertOrderStatus = async (order, globalProfile) => {

  let updatedOrder = { ...order };

  // Check if history is an array and has more than one element
  if (Array.isArray(updatedOrder.history) && updatedOrder.history.length > 1) {

    // Remove the last element
    updatedOrder.history.pop();

    // Update the status to the value of the last element in the history after popping
    updatedOrder.status = updatedOrder.history[updatedOrder.history.length - 1].status;

    const lastHistoryItem = updatedOrder.history[updatedOrder.history.length - 1];

    if (updatedOrder.historyDates) {
      console.log(lastHistoryItem.status)
      delete updatedOrder.historyDates[order.status];
    }

    await buildOrderStatusChangeNotifications(order, globalProfile, lastHistoryItem, 'reverted');
  }

  // Return the updated order
  return updatedOrder;
}

export const OrderStatus = [
  {
    label: "Offer",
    value: ("Offer"),
    url: 'offer',
    color: 'secondary',
    conditions: [
      {
        title: 'Quotation Created',
        function: isQuotationCreated,
      },
      {
        title: 'At least one payment has been made',
        function: orderHasPayment,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to next status, please create the quotation, make sure at least one payment is made',
    statusPermissions: ['Admin', 'Accountant', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Field Installation Manager']

  },
  {
    label: "Order",
    value: ("Order"),
    url: 'order',
    color: 'secondary',
    conditions: [
      {
        title: 'Manufacturing Inspection Uploaded',
        function: isDocumentManufacturingInspectionUploaded,
      },
      {
        title: 'Manufacturing Approval Declaration Uploaded',
        function: isDocumentManufacturingApprovalDeclarationUploaded,
      }
    ],
    revertPermissions: ['Admin', 'Accountant'],
    criteria: 'To move the order to next status, please make sure you upload Manufacturing Inspection document and Manufacturing Approval Declaration document ',
    statusPermissions: ['Admin', 'Purchasing Officer', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Field Installation Manager']
  },
  {
    label: "Production",
    value: ("Production"),
    url: 'production',
    color: 'secondary',
    conditions: [
      {
        title: 'Factory Cost Specification Uploaded',
        function: isDocumentFactoryCostSpecificationUploaded,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to next status, please make sure you upload the Factory Cost Specification Document',
    statusPermissions: ['Admin', 'Purchasing Officer', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Field Installation Manager']
  },
  {
    label: "Pending Delivery",
    value: ("Pending Delivery"),
    url: 'pending-delivery',
    color: 'secondary',
    conditions: [
      {
        title: ' Factory Receipt Specification Uploaded',
        function: isDocumentFactoryReceiptSpecificationUploaded,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to the next status, please upload the Factory Receipt Specification. ',
    statusPermissions: ['Admin', 'Warehouse Supervisor', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Warehouse Supervisor', 'Field Installation Manager']

  },
  {
    label: "In Stock",
    value: ("In Stock"),
    url: 'in-stock',
    color: 'secondary',
    conditions: [
      {
        title: ' Assembly Request Document',
        function: isDocumentAssemblyRequestDocumentUploaded,
      },
      {
        title: ' Order is paid in full (100%)',
        function: isOrderPaidFull,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to next status, please make sure you uploaded Assembly Request Document and ensure the order is paid in full (100%)',
    statusPermissions: ['Admin', 'Warehouse Supervisor', 'Accountant', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Warehouse Supervisor', 'Field Installation Manager']
  },
  {
    label: "Ready",
    value: ("Ready"),
    url: 'ready',
    color: 'secondary',
    conditions: [
      {
        title: ' Goods Delivery Declaration',
        function: isDocumentGoodsDeliveryDeclarationUploaded,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to next status, please make sure you uploaded Goods Delivery Declaration',
    statusPermissions: ['Admin', 'Warehouse Supervisor', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Warehouse Supervisor', 'Field Installation Manager']
  },
  {
    label: "Delivered",
    value: ("Delivered"),
    url: 'delivered',
    color: 'success',
    conditions: [
      {
        title: 'Delivery Report',
        function: isDocumentDeliveryReportUploaded,
      },
      {
        title: 'Technicians Bonus Added',
        function: isTechniciansBonusAdded,
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'To move the order to next status, please make sure you uploaded Delivery Report',
    statusPermissions: ['Admin', 'Field Installation Manager', 'Sales Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Warehouse Supervisor', 'Field Installation Manager']
  },
  {
    label: "Installed",
    value: ("Installed"),
    url: 'installed',
    color: 'success',
    conditions: [
      {
        title: 'Order is Installed',
        function: (order) => order?.status === 'Installed',
      }
    ],
    revertPermissions: ['Admin'],
    criteria: 'Order is installed, please review all orders documents to make sure its not missing any document',
    statusPermissions: ['Admin', 'Sales Manager', 'Field Installation Manager'],
    tabsPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Purchasing Officer', 'Warehouse Supervisor', 'Field Installation Manager']
  },
]

export const OrderSupportedUploadableDocuments = [
  {
    name: "Manufacturing Inspection",
    arabicName: "معاينة تصنيع",
    value: ("Manufacturing Inspection"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Sales', 'Admin', 'Accountant', 'Field Installation Manager', 'Sales Manager'],
    description: "A document outlining the inspection of manufacturing processes and materials to ensure they meet the required standards and specifications."
  },
  {
    name: "Manufacturing Approval Declaration",
    arabicName: "اقرار موافقة التصنيع",
    value: ("Manufacturing Approval Declaration"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Sales', 'Admin', 'Accountant', 'Purchasing Officer', 'Field Installation Manager', 'Sales Manager'],
    description: "A formal declaration approving the commencement of manufacturing based on predefined criteria and designs."
  },
  {
    name: "Factory Cost Specification",
    arabicName: "فاتورة استلام المصنع",
    value: ("Factory Cost Specification"),
    viewPermissions: ['Admin', 'Field Installation Manager', 'Sales Manager'],
    permissions: ['Admin', 'Field Installation Manager', 'Sales Manager'],
    description: "A detailed document specifying the dimensions, materials, and requirements for the manufactured goods, confirming their compliance before acceptance by the factory.",
  },
  {
    name: "Factory Receipt Specification",
    arabicName: "مقايسة استلام المصنع",
    value: ("Factory Receipt Specification"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Warehouse Supervisor', 'Field Installation Manager', 'Sales Manager'],
    description: "A detailed document specifying the dimensions, materials, and requirements for the manufactured goods, confirming their compliance before acceptance by the factory.",
  },
  {
    name: "Assembly Request Document",
    arabicName: "طلب تجميع",
    value: ("Assembly Request Document"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Accountant', 'Warehouse Supervisor', 'Field Installation Manager', 'Sales Manager'],
    description: "A formal request to assemble components into the final product as per the manufacturing plan.",
  },
  {
    name: "Goods Delivery Declaration",
    arabicName: "اقرار تسليم بضاعة",
    value: ("Goods Delivery Declaration"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Warehouse Supervisor', 'Field Installation Manager', 'Sales Manager'],
    description: "A declaration confirming the delivery of goods, ensuring they have been handed over to the customer or next stage of the process.",
  },
  {
    name: "Delivery Report",
    arabicName: "محضر تسليم",
    value: ("Delivery Report"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Field Installation Manager', 'Sales Manager'],
    description: "An official report documenting the delivery of goods, including details of the items, delivery date, and recipient acknowledgment.",
  },
  {
    name: "Receipts",
    arabicName: "فواتير",
    value: ("Receipts"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    description: "A digital record of payment, providing proof of transaction including details such as amount paid, date, and method of payment.",
  },
  {
    name: "Customer Documents",
    arabicName: "ملفات العميل",
    value: ("Customer Documents"),
    viewPermissions: ['Admin'],
    permissions: ['Admin'],
    description: "Upload any documents here that you want to share was customer",
  },
  {
    name: "Other",
    arabicName: "اخري",
    value: ("Other"),
    viewPermissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    permissions: ['Admin', 'Sales', 'Accountant', 'Sales Manager', 'Customer Service', 'Warehouse Supervisor', 'Purchasing Officer', 'Field Installation Manager'],
    description: "Other relevant documents for reference."
  }
]
