import NotificationSound from 'assets/sound/notificaion-sound.mp3'
import logo from 'assets/images/logo-simple.png';
import React, { forwardRef, useRef } from 'react';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SnackbarContent } from 'notistack';


// Function to show the browser notification
const showBrowserNotification = (ref, notification) => {
  const title = notification.title || 'New Notification';
  const options = {
    body: notification.body || 'You have a new notification!',
    icon: logo,
    tag: notification.id,
  };

  // Show the notification
  const notif = new Notification(title, options);

  if (ref.current) {
    // Play a sound when the notification is displayed
    ref.current.play()
      .catch(error => {
        // error
      });
    // Optional: Add click event to the notification
    notif.onclick = (e) => {
      window.focus();
      console.log("Notification clicked");
      e.target.close();
    };
  }

};

export const SuccessSnackbar = forwardRef((props, ref) => {

  const theme = useTheme();
  const { message } = props;

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.success.main}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <CheckCircleOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});


export const ErrorSnackbar = forwardRef((props, ref) => {
  const { message } = props;
  const theme = useTheme();

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.error.main}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <ErrorOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});

export const WarningSnackbar = forwardRef((props, ref) => {
  const { message } = props;
  const theme = useTheme();

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.warning.dark}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <ErrorOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});


export const NotificationSnack = forwardRef((props, ref) => {
  const { notification } = props;
  const theme = useTheme();
  const audioPlayer = useRef(null);

  // Trigger browser notification
  if (Notification.permission === "granted") {
    showBrowserNotification(audioPlayer, notification);
  }

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
      backgroundColor: `${theme.palette.primary.main}B0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <audio ref={audioPlayer} src={NotificationSound} />

      <Box sx={{ p: 2 }} display="flex" alignItems="center" gap={2}>
        <img
          alt="logo"
          src={logo}
          style={{
            maxWidth: '30px',
            filter: 'brightness(0) invert(1)'
          }}
        />
        <Stack direction={'column'}>
          <Typography variant="caption" color="white">
            {notification?.title}
          </Typography>
          <Typography variant="body2" color="white">
            {notification?.body}
          </Typography>
        </Stack>
      </Box>
    </SnackbarContent>
  );
});

