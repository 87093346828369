

import {
  initializeApp
} from 'firebase/app';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from 'firebase/storage';

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';

import {
  getFunctions,
  httpsCallable
} from 'firebase/functions'

import {
  getFirestore,
  Timestamp,
  collection,
  setDoc,
  where,
  orderBy,
  query,
  increment,
  doc,
  getDoc,
  onSnapshot,
  startAfter,
  getDocs,
  addDoc,
  writeBatch,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  limit,
  serverTimestamp
} from 'firebase/firestore';

const firebaseConfigurations = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(
  firebaseConfigurations,
  {
    cacheSizeBytes: 100 * 1024 * 1024,
    synchronizeTabs: true,
    cacheEnabled: true,
  }
);

// Access Firebase services
const firestoreDatabase = getFirestore();
const firebaseAuth = getAuth();
const firebaseStorage = getStorage();
const functions = getFunctions();

// Auth
export {
  firebaseAuth,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut
}

// functions
export {
  functions,
  httpsCallable
}

// Storage
export {
  firebaseStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
}

// Firestore
export {
  firestoreDatabase,
  collection,
  where,
  orderBy,
  query,
  getDoc,
  doc,
  increment,
  getDocs,
  startAfter,
  addDoc,
  setDoc,
  onSnapshot,
  updateDoc,
  limit,
  deleteDoc,
  writeBatch,
  getCountFromServer,
  Timestamp,
  serverTimestamp
}