
import { viewCurrency, viewNumber } from 'utility/math';

export const generalConfig = {
    logo: {
        main: require('assets/images/logo.png'),
        small: require('assets/images/logo-small.png'),
        simple: require('assets/images/logo-simple.png'),
    },
    number: {
        function: viewNumber,
    },
    currency: {
        function: viewCurrency,
    },
};

