import { lazy } from 'react';

// project imports
import Loadable from 'Loadable';
import MinimalLayout from 'panel/src/views/layout/MinimalLayout';
import { SystemMaintaianceGuard } from 'database/guards/AuthGuard';

const Maintenance = Loadable(lazy(() => import('views/Maintenance')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const maintaianceRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'maintenance',
      element: <SystemMaintaianceGuard />,
      children: [
        {
          path: '',
          element: <Maintenance />
        }
      ]
    }
  ]
};

export default maintaianceRoutes;
