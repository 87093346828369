// ==============================|| UTILITIES MENU ITEMS ||============================== //

import { IconAppWindow } from "@tabler/icons-react";

const website = {
  permissions: ['Admin'],
  id: 'website',
  title: 'Website',
  type: 'group',
  children: [
    {
      permissions: ['Admin'],
      id: 'website',
      title: 'Website',
      type: 'item',
      icon: IconAppWindow,
      url: '/website',
    }
  ]
};

export default website;
