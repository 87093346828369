
export const LaborsActivityType = [
  'Supervision',
  'Installation',
  'Assembly'
]

export const newLaborItem = (quotation) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      labors: [
        ...(quotation.cost && quotation.cost.labors ? quotation.cost.labors : []),
        {
          labor: null,
          commission: 0,
          NoOfWindows: 0,
        },
      ],
    },
  };
};

export const removeLaborItem = (quotation, index) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      labors: quotation.cost.labors.filter((_, i) => i !== index),
    },
  };
};

export const getLaborTotalCommison = (item) => {
  var total = 0;
  total = item.commission * item.NoOfWindows;
  return total;
}

export const getQuotationLabors = (quotation) => (quotation && quotation?.cost?.labors) ? quotation?.cost?.labors : [];


export const getTravelExpencesValue = (quotation) => {
  var expences = 0;
  if (quotation.cost) {
    if (quotation.cost.travelExpences) {
      expences = quotation.cost.travelExpences
    }
  }
  return expences
}

export const setTravelExpencesValue = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      travelExpences: parseFloat(value),
    },
  };
};

export const getExtraLaborValue = (quotation) => {
  var extraLabor = 0;
  if (quotation.cost) {
    if (quotation.cost.extraLabor) {
      extraLabor = quotation.cost.extraLabor
    }
  }
  return extraLabor
}

export const setExtraLaborValue = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      extraLabor: parseFloat(value),
    },
  };
};

export const getLaborTotal = (quotation) => {
  var total = 0;
  if (quotation.cost) {
    if (quotation.cost.labors) {
      for (var i = 0; i < quotation.cost.labors.length; i++) {
        total += getLaborTotalCommison(quotation.cost.labors[i])
      }
    }
    total += getExtraLaborValue(quotation)
    total += getTravelExpencesValue(quotation)
  }

  return total
}