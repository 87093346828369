import { lazy } from 'react';

// project imports
import Home from 'panel/src/pages/Home';
import Loadable from 'Loadable';

import { PermissionsGuard } from 'database/guards/PermissionsGuard';
import { AuthGuard } from 'database/guards/AuthGuard';
import { productsType } from 'values/Includes';
import { OrderStatus } from 'model/order';

const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

const Users = Loadable(lazy(() => import('views/Users')));
const NewUser = Loadable(lazy(() => import('views/Users/NewUser')));
const EditUser = Loadable(lazy(() => import('views/Users/EditUser')));

const Products = Loadable(lazy(() => import('views/Products')));
const NewProduct = Loadable(lazy(() => import('views/Products/NewProduct')));
const EditProduct = Loadable(lazy(() => import('views/Products/EditProduct')));

const Labels = Loadable(lazy(() => import('views/Labels')));
const NewLabel = Loadable(lazy(() => import('views/Labels/NewLabel')));
const EditLabel = Loadable(lazy(() => import('views/Labels/EditLabel')));

const Customers = Loadable(lazy(() => import('views/Customers')));
const NewCustomer = Loadable(lazy(() => import('views/Customers/NewCustomer')));
const CustomerManagement = Loadable(lazy(() => import('views/Customers/CustomerManagement')));

const Areas = Loadable(lazy(() => import('views/Areas')));
const NewArea = Loadable(lazy(() => import('views/Areas/NewArea')));
const EditArea = Loadable(lazy(() => import('views/Areas/EditArea')));

const Referrals = Loadable(lazy(() => import('views/Referral')));
const NewReferral = Loadable(lazy(() => import('views/Referral/NewReferral')));
const EditReferral = Loadable(lazy(() => import('views/Referral/EditReferral')));

const Technicians = Loadable(lazy(() => import('views/Technicians')));
const NewTechnician = Loadable(lazy(() => import('views/Technicians/NewTechnician')));
const EditTechnician = Loadable(lazy(() => import('views/Technicians/EditTechnician')));

const Orders = Loadable(lazy(() => import('views/Orders')));

const NewOrder = Loadable(lazy(() => import('views/Orders/NewOrder')));
const OrderManagement = Loadable(lazy(() => import('views/Orders/OrderManagement')));

const Reports = Loadable(lazy(() => import('views/Reports')));

const Notifications = Loadable(lazy(() => import('views/Notifications')));

const SystemManagement = Loadable(lazy(() => import('views/SystemManagement')));

const ProductsDataExchange = Loadable(lazy(() => import('views/DataExchange/ProductsDataExchange')));

const SalesTargets = Loadable(lazy(() => import('views/Targets/SalesTargets')));

const Website = Loadable(lazy(() => import('views/Website')));
const Hero = Loadable(lazy(() => import('views/Website/hero')));
const Clients = Loadable(lazy(() => import('views/Website/clients')));
const NewClient = Loadable(lazy(() => import('views/Website/clients/NewClient')));
const EditClient = Loadable(lazy(() => import('views/Website/clients/EditClient')));

const Partners = Loadable(lazy(() => import('views/Website/partners')));
const NewPartner = Loadable(lazy(() => import('views/Website/partners/NewPartner')));
const EditPartner = Loadable(lazy(() => import('views/Website/partners/EditPartner')));

const Resources = Loadable(lazy(() => import('views/Website/resources')));
const NewResource = Loadable(lazy(() => import('views/Website/resources/NewResource')));
const EditResource = Loadable(lazy(() => import('views/Website/resources/EditResource')));

const Technical = Loadable(lazy(() => import('views/Technical')));


// ==============================|| MAIN ROUTING ||============================== //

const authenticatedRoutes = {
  path: '/',
  element: <AuthGuard />,
  children: [
    {
      path: '/',
      element: <Home />,
      children: [
        {
          path: '',
          element:
            <PermissionsGuard
              props={[
                'Admin',
                'Sales',
                'Customer Service',
                'Sales Manager',
                'Field Installation Manager',
                'Warehouse Supervisor',
                'Purchasing Officer',
                'Accountant'
              ]}>
              <Dashboard />

            </PermissionsGuard>,
        },
        {
          path: 'dashboard',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
              'Accountant'
            ]}>
              <Dashboard />
            </PermissionsGuard>,
        },
        {
          path: 'users',
          element:
            <PermissionsGuard props={[
              'Sales Manager',
              'Field Installation Manager',
              'Admin'
            ]}>
              <Users />
            </PermissionsGuard>,
        },
        {
          path: 'users/new-user',
          element:
            <PermissionsGuard
              props={[
                'Sales Manager',
                'Field Installation Manager',
                'Admin'
              ]}>
              <NewUser />
            </PermissionsGuard>,
        },
        {
          path: 'users/:userID',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <EditUser />
            </PermissionsGuard>,
        },
        {
          path: 'products',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Products />
            </PermissionsGuard>,
        },
        ...productsType.map((type) => ({
          path: `products/${type.value}`,
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Products />
            </PermissionsGuard>,
        })),
        {
          path: 'products/new-product',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewProduct />
            </PermissionsGuard>,
        },
        ...productsType.map((type) => ({
          path: `products/${type.value}/new-product`,
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewProduct />
            </PermissionsGuard>,
        })),
        {
          path: 'products/:productID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditProduct />
            </PermissionsGuard>,
        },
        ...productsType.map((type) => ({
          path: `products/${type.value}/:productID`,
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditProduct />
            </PermissionsGuard>,
        })),
        {
          path: 'labels',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Labels />
            </PermissionsGuard>,
        },
        {
          path: 'labels/new-label',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewLabel />
            </PermissionsGuard>,
        },
        {
          path: 'labels/:labelID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditLabel />
            </PermissionsGuard>,
        },
        {
          path: 'technicians',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <Technicians />
            </PermissionsGuard>,
        },
        {
          path: 'technicians/new-technician',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Field Installation Manager'
            ]}>
              <NewTechnician />
            </PermissionsGuard>,
        },
        {
          path: 'technicians/:laborID',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Field Installation Manager'
            ]}>
              <EditTechnician />
            </PermissionsGuard>,
        },
        {
          path: 'customers',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Customers />
            </PermissionsGuard>,
        },
        {
          path: 'customers/new-customer',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <NewCustomer />
            </PermissionsGuard>,
        },
        {
          path: 'customers/:customerID',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <CustomerManagement />
            </PermissionsGuard>,
        },
        {
          path: 'customers/:customerID/edit',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <CustomerManagement />
            </PermissionsGuard>,
        },
        {
          path: 'customers/:customerID/orders',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <CustomerManagement />
            </PermissionsGuard>,
        },
        {
          path: 'areas',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <Areas />
            </PermissionsGuard>,
        },
        {
          path: 'areas/new-area',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales'
            ]}>
              <NewArea />
            </PermissionsGuard>,
        },
        {
          path: 'areas/:areaID',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales'
            ]}>
              <EditArea />
            </PermissionsGuard>,
        },
        {
          path: 'referrals',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <Referrals />
            </PermissionsGuard>,
        },
        {
          path: 'referrals/new-referral',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewReferral />
            </PermissionsGuard>,
        },
        {
          path: 'referrals/:referralID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditReferral />
            </PermissionsGuard>,
        },
        {
          path: 'orders',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Orders />
            </PermissionsGuard>,
        },
        ...OrderStatus.map((status) => ({
          path: `orders/${status.url}`,
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <Orders />
            </PermissionsGuard>,
        })),
        {
          path: 'orders/new-order',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Sales Manager',
              'Field Installation Manager'
            ]}>
              <NewOrder />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/status',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/quotation-management',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/accounting',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Accountant',
              'Field Installation Manager'
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/technicians',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Accountant',
              'Field Installation Manager'
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/documents',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/documents/uploadables',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/documents/downloadables',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/cost-calculation',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/job-order',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'orders/:orderID/maintenance',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
              'Warehouse Supervisor',
              'Purchasing Officer',
            ]}>
              <OrderManagement />
            </PermissionsGuard>,
        },
        {
          path: 'reports',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales',
              'Accountant',
              'Customer Service',
              'Sales Manager',
              'Field Installation Manager',
            ]}>
              <Reports />
            </PermissionsGuard>,
        },
        {
          path: 'notifications',
          element:
            <PermissionsGuard props={[
              'Admin',
            ]}>
              <Notifications />
            </PermissionsGuard>,
        },
        {
          path: 'targets',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager'
            ]}>
              <SalesTargets />
            </PermissionsGuard>,
        },
        {
          path: 'targets/sales',
          element:
            <PermissionsGuard props={[
              'Admin',
              'Sales Manager',
              'Field Installation Manager'
            ]}>
              <SalesTargets />
            </PermissionsGuard>,
        },
        {
          path: 'system-management',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <SystemManagement />
            </PermissionsGuard>,
        },
        {
          path: 'system-management/system-information',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <SystemManagement />
            </PermissionsGuard>,
        },
        {
          path: 'system-management/terms-and-conditions',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <SystemManagement />
            </PermissionsGuard>,
        },
        {
          path: 'system-management/terms-and-conditions/quotation',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <SystemManagement />
            </PermissionsGuard>,
        },
        {
          path: 'system-management/terms-and-conditions/warranty',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <SystemManagement />
            </PermissionsGuard>,
        },
        {
          path: 'website',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <Website />
            </PermissionsGuard>,
        },
        {
          path: 'website/hero',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <Hero />
            </PermissionsGuard>,
        },
        {
          path: 'website/clients',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <Clients />
            </PermissionsGuard>,
        },
        {
          path: 'website/clients/new-client',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewClient />
            </PermissionsGuard>,
        },
        {
          path: 'website/clients/:clientID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditClient />
            </PermissionsGuard>,
        },
        {
          path: 'website/partners',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <Partners />
            </PermissionsGuard>,
        },
        {
          path: 'website/partners/new-partner',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewPartner />
            </PermissionsGuard>,
        },
        {
          path: 'website/partners/:partnerID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditPartner />
            </PermissionsGuard>,
        },
        {
          path: 'website/resources',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <Resources />
            </PermissionsGuard>,
        },
        {
          path: 'website/resources/new-resource',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <NewResource />
            </PermissionsGuard>,
        },
        {
          path: 'website/resources/:resourceID',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <EditResource />
            </PermissionsGuard>,
        },
        {
          path: 'data-exchange',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <ProductsDataExchange />
            </PermissionsGuard>,
        },
        {
          path: 'data-exchange/products',
          element:
            <PermissionsGuard props={[
              'Admin'
            ]}>
              <ProductsDataExchange />
            </PermissionsGuard>,
        },
        {
          path: 'technical',
          element:
            <PermissionsGuard props={[
              'Technical'
            ]}>
              <Technical />
            </PermissionsGuard>,
        }
      ]
    }
  ]
};

export default authenticatedRoutes;
