import NotificationsView from "panel/cfg/view/NotificationsView";
import ProfileSection from "panel/cfg/view/ProfileSection";

const viewConfig = {
  header: {
    notifications: {
      component: NotificationsView,
      enabled: true,
      props: {
      },
    },
    profile: {
      component: ProfileSection,
      enabled: true,
      props: {
      },
    },
  },
};

export default viewConfig;