import { lazy } from 'react';

// project imports
import Loadable from 'Loadable';
import MinimalLayout from 'panel/src/views/layout/MinimalLayout';
import { UnauthorizedGuard } from 'database/guards/AuthGuard';

const Unauthorized = Loadable(lazy(() => import('panel/src/pages/Unauthorized')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const unauthorizedRoutes = {
  path: '/',
  element: <UnauthorizedGuard />,
  children: [{
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: '/unauthorized',
        element: <Unauthorized />
      }
    ]
  }]

};

export default unauthorizedRoutes;
