import React, { useState, useEffect } from 'react';
import { Avatar, Box, Skeleton } from '@mui/material';

const ProfilePictureAvatar = (props) => {
  const { src, loading, sx = {}, ...otherProps } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageSrc(src);
      setImageLoaded(true);
    };
    img.onerror = () => {
      setImageSrc(null);
      setImageLoaded(true);
    };
    img.src = src;
    setImageLoaded(false);

  }, [src]);

  return (
    <Box
      sx={{
        width: sx.width,
        height: sx.height,
        borderRadius: sx.borderRadius,
        p: sx.p,
        m: sx.m,
        mb: sx.mb,
      }}
    >
      {(!imageLoaded || loading) ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: sx.borderRadius }}
        />
      ) : (
        <Avatar
          src={imageSrc}
          {...otherProps}
          sx={{
            display: 'flex',
            borderRadius: sx.borderRadius,
            transition: 'all .2s ease-in-out',
            backgroundColor: 'secondary.light',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'secondary.dark',
            ...sx,
          }}
        />
      )}
    </Box>
  );
};


export default ProfilePictureAvatar;