import {
  resetUserPassword,
  signIn,
} from 'database/firebase/api/auth';
import PermissionView from 'database/guards/PermissionView';

export const databaseConfig = {
  login: {
    function: signIn,
    errors: {
      invalidEmail: { field: 'email', message: 'Incorrect email address' },
      userNotFound: { field: 'email', message: 'Email address not found' },
      wrongPassword: { field: 'password', message: 'Wrong password' },
      default: { field: 'submit', message: 'Error, please try again' },
    },
    errorMap: {
      'auth/invalid-email': 'invalidEmail',
      'auth/user-not-found': 'userNotFound',
      'auth/wrong-password': 'wrongPassword',
    },
  },
  resetPassword: {
    function: resetUserPassword,
    errors: {
      invalidEmail: { field: 'email', message: 'Incorrect email address' },
      userNotFound: { field: 'email', message: 'Email address not found' },
      default: { field: 'submit', message: 'Error, please try again' },
    },
    errorMap: {
      'auth/invalid-email': 'invalidEmail',
      'auth/user-not-found': 'userNotFound',
    },
  },
  isAuthorized: (requiredPermissions, children) => (
    <PermissionView requiredPermissions={requiredPermissions}>
      {children}
    </PermissionView>
  ),
};

