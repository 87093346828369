import {
  IconUsers,
  IconArchive,
  IconTag,
  IconTruckDelivery
} from '@tabler/icons-react';

const entities = {
  permissions: [
    'Admin',
    'Sales',
    'Accountant',
    'Customer Service',
    'Sales Manager',
    'Field Installation Manager',
    'Warehouse Supervisor',
    'Purchasing Officer'
  ],
  id: 'entities',
  title: 'entities',
  type: 'group',
  children: [
    {
      permissions: [
        'Admin',
        'Sales Manager',
        'Field Installation Manager',
      ],
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: IconUsers,
    },
    {
      permissions: [
        'Admin',
        'Sales',
        'Accountant',
        'Customer Service',
        'Sales Manager',
        'Field Installation Manager',
        'Warehouse Supervisor',
        'Purchasing Officer',
      ],
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/products/Sector',
      icon: IconArchive,
    },
    {
      permissions: [
        'Admin',
        'Sales Manager',
        'Field Installation Manager',
        'Warehouse Supervisor',
        'Purchasing Officer'
      ],
      id: 'labels',
      title: 'Labels',
      type: 'item',
      url: '/labels',
      icon: IconTag,
    },
    {
      permissions: [
        'Admin',
        'Sales Manager',
        'Field Installation Manager',
      ],
      id: 'technicians',
      title: 'Technicians',
      type: 'item',
      url: '/technicians',
      icon: IconTruckDelivery,
    }
  ]
};

export default entities;
