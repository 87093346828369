import { lazy } from 'react';

// project imports
import Loadable from 'Loadable';
import MinimalLayout from 'panel/src/views/layout/MinimalLayout';
import { PublicGuard } from 'database/guards/AuthGuard';

const Login = Loadable(lazy(() => import('panel/src/pages/Login')));
const ForgetPassword = Loadable(lazy(() => import('panel/src/pages/ForgetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const publicRoutes = {
  path: '/',
  element: <PublicGuard />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'forget-password',
          element: <ForgetPassword />
        }
      ]
    }
  ]

};

export default publicRoutes;
