import dashboard from './dashboard';
import entities from './entities';
import customers from './customers';
import operations from './operations';
import administrative from './administrative';
import website from './website';
import reports from './reports';
import dataExchange from './dataExchange';
import technical from './technical';
import notifications from './notifications';
import targets from './targets';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, entities, customers, operations, reports, notifications, targets, dataExchange, website, administrative, technical]
};

export default menuItems;
