import _ from 'lodash';

/*==============================================================*/
/* Quotation                                                    */
/*==============================================================*/

export const copyLastAccessoriesItem = (quotation) => {
  if (!quotation.accessories?.length) return quotation;
  const newAccessoriesItem = _.cloneDeep(quotation.accessories[quotation.accessories.length - 1]);
  return {
    ...quotation,
    accessories: [...quotation.accessories, newAccessoriesItem],
  };
};

export const getQuotationAccessoriesItemsFields = (quotation, fieldName) => {
  const uniqueFields = new Set(); // To store unique field values

  // Check if quotation and accessory section exist
  if (quotation && quotation.accessories && Array.isArray(quotation.accessories)) {
    // Loop through each item in the accessory array
    quotation.accessories.forEach((item) => {
      if (item && item[fieldName]) {
        uniqueFields.add(item[fieldName]); // Add the field value
      }
    });
  }

  // Convert set to array and join unique field values with a comma
  return Array.from(uniqueFields).join(', ');
};

export const getQuotationAccessoriesItemsNames = (quotation, productsData) => {
  const uniqueNames = new Set();

  // Check if quotation and blinds section exist
  if (quotation?.accessories && Array.isArray(quotation.accessories)) {
    // Loop through each item in the blinds array
    quotation.accessories.forEach((item) => {
      let id = item?.id;
      if (id) {
        const productName = productsData[id]?.info?.name;
        if (productName) {
          uniqueNames.add(productName);
        }
      }
    });
  }

  // Convert set to array and join unique names with a comma
  return Array.from(uniqueNames).join(', ');
};

export const getAccessoryItemsLength = (quotation) => {
  var length = 0;
  if (quotation.accessories) {
    length = quotation.accessories.length;
  }
  return length;
};

export const isAccessoriesQuotationValid = (quotation) => {

  var result = {
    header: 'Success',
    message: 'Quotation valid',
    success: true
  }

  var i = 0;

  for (i = 0; i < quotation.accessories.length; i++) {
    if (quotation.accessories[i].id === null) {
      result.header = 'Error';
      result.message = 'Product for acessories item row ' + (i + 1) + ' is not selected'
      result.success = false;
      return result
    }
  }

  return result;
}


export const AddAccessoryItem = (quotation) => {
  return {
    ...quotation,
    accessories: [
      ...(quotation.accessories || []),
      {
        id: null,
        quantity: 1,
        multiplier: 1,
        printable: true,
      },
    ],
  };
};

export const RemoveAccessoryItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1 && updatedQuotation.accessories) {
    updatedQuotation.accessories.splice(index, 1);

    if (updatedQuotation.accessories.length === 0) {
      delete updatedQuotation.accessories;
    }
  }

  return updatedQuotation;
};

export const getQuotationAccessories = (quotation) => (quotation && quotation.accessories) ? quotation.accessories : [];

export const getQuotationAccessoriesTotal = (quotation) => {
  var total = 0;
  if (quotation.accessories) {
    for (var i = 0; i < quotation.accessories.length; i++) {
      total += getAccessoryItemTotal(quotation.accessories[i])
    }
  }

  return total;
}

export const getAccessoriesSubTotal = (quotation) => {
  if (Array.isArray(quotation.accessories) && quotation.accessories.length > 0) {
    return quotation.accessories.reduce((total, accessory) => {
      if (accessory && accessory.salesPrice) {
        return total + accessory.salesPrice;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getAccessoriesTotalQuantity = (quotation) => {
  if (Array.isArray(quotation.accessories) && quotation.accessories.length > 0) {
    return quotation.accessories.reduce((total, accessory) => {
      if (accessory && accessory.quantity) {
        return total + accessory.quantity;
      }
      return total;
    }, 0);
  }

  return 0;
};

export const getAccessoryItemTotal = (accessoryItem) => {
  var total = 0;
  var salesPrice = accessoryItem.id ? accessoryItem.salesPrice : 0;

  total = (salesPrice ?? 0) * (accessoryItem.multiplier ?? 0) * (accessoryItem.quantity ?? 0);

  return total;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const getCostAccessoryTotal = (quotation) => {
  var total = 0;

  if (quotation.accessories) {
    for (var i = 0; i < quotation.accessories.length; i++) {
      total += getCostAccessoryTotalItem(quotation.accessories[i])
    }
  }

  return total
}

export const getCostAccessoryTotalItem = (accessoryItem) => {
  var total = 0;
  var costPrice = 0;
  if (accessoryItem.id) {
    costPrice = accessoryItem.costPrice;
  }
  total = costPrice * accessoryItem.multiplier * accessoryItem.quantity
  return total
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/