import NotificationCard from './NotificationCard';
import EmptyLayout from 'panel/src/views/layout/EmptyLayout';
import SubCard from 'panel/src/views/cards/SubCard';

import React, { useContext, useEffect, useState } from 'react';
import { Button, Popover, Badge, Stack, useTheme, IconButton } from '@mui/material';
import { attachNotificationsListner } from 'database/repositories/UsersRepo';
import { AuthContext } from 'database/providers/AuthProvider';
import { updateNotificaion } from 'database/repositories/NotificationsRepo';
import { Notifications, NotificationsNone } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

export default function NotificationsView() {
  const { globalProfile } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = attachNotificationsListner(globalProfile.id, (newNotifications) => {
      setNotifications(newNotifications);

      // Ensure newNotifications is an array and has at least one item
      if (newNotifications && newNotifications.length > 0) {
        const latestNewNotification = newNotifications[0];
        if (latestNewNotification && !latestNewNotification?.seen) {
          enqueueSnackbar('Notification', {
            variant: 'notification',
            notification: latestNewNotification
          });
        }
      }
    });

    // Cleanup the listener on component unmount
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [globalProfile.id]);

  const handleClicked = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMarkAllAsReadClicked = () => {
    // Create a copy of the notifications state
    const updatedNotifications = notifications.map(noti => {
      // Mark each notification as seen
      return { ...noti, seen: true };
    });

    // Update the notifications in the state
    setNotifications(updatedNotifications);

    // Update each notification in the database
    updatedNotifications.forEach(noti => {
      updateNotificaion(globalProfile.id, noti.id, noti);
    });
  };

  const handleNotificationCardClicked = (notification) => {

    // Create a copy of the notification and mark it as seen
    const updatedNotification = { ...notification, seen: true };

    // Update the notifications in the state
    const updatedNotifications = notifications.map(noti =>
      noti.id === notification.id ? updatedNotification : noti
    );
    setNotifications(updatedNotifications);

    // Update the notification in the database
    updateNotificaion(globalProfile.id, updatedNotification.id, updatedNotification);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Filter unseen notifications
  const unseenNotifications = notifications.filter(notification => notification.seen === null);

  const renderNotificationButton = () => {
    return (
      <>
        <Badge
          badgeContent={unseenNotifications.length}
          color="error"
          invisible={unseenNotifications.length === 0}
        >
          <IconButton
            onClick={handleClicked}
            sx={{
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            {unseenNotifications.length > 0 ? <Notifications /> : <NotificationsNone />}
          </IconButton>
        </Badge>


      </>
    )
  }

  const renderPopOverMenu = () => {
    return (
      <Popover
        id={id}
        open={open}
        sx={{ maxHeight: '90%', borderRadius: '15px' }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              borderRadius: '15px',
              mt: 2,
              maxWidth: ['90%', '80%', '30%'], // Set a maximum width
            },
          },
        }}
      >
        {notifications.length === 0 ? (
          <EmptyLayout
            icon={NotificationsNone}
            title="No Notifications Available"
            message="You currently have no new notifications. Check back later for updates or stay tuned for new alerts."
          />
        ) : (
          <SubCard
            title='Notifications'
            secondary={
              <Button
                color='background'
                onClick={handleMarkAllAsReadClicked}
                sx={{ mb: 2, borderRadius: '5px' }}>
                Mark all as read
              </Button>
            }>

            {notifications.map((notification, index) => (
              <Stack key={notification.id}>
                <NotificationCard
                  mode='list'
                  onClick={handleNotificationCardClicked}
                  notification={notification}
                  sx={{
                    borderRadius: index === 0 ? '16px 16px 0 0' : '0', // Apply rounded corners only to the first child
                  }} />
              </Stack>
            ))}
          </SubCard>
        )}
      </Popover>
    )
  }
  return (
    <>
      {renderNotificationButton()}
      {renderPopOverMenu()}
    </>
  );
}